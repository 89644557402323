import * as React from "react";
import { useTranslation, Trans } from "react-i18next";
import { Link } from "react-router-dom";
import { getCookie, removeCookie } from "tiny-cookie";
import Empty from "~/components/Empty";
import Scene from "~/components/Scene";

const Error403: React.FC = () => {
    const [domain, setDomain] = React.useState(new URL(window.location.href).host);

    if (domain.startsWith("app")) {
        setDomain(domain.slice(4))
    }

    if (getCookie("savePath")) {
        removeCookie("savePath", { domain });
    }

    const { t } = useTranslation()
    return (
        <Scene title={t("Access error")}>
            <h1>{t("Access error")}</h1>
            <Empty>
                <Trans>
                    It looks like you don't have access to this page. Go to the{" "}
                    <Link to="/">homepage</Link>?
                </Trans>
            </Empty>
        </Scene>
    );
};

export default Error403;
