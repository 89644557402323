import React, { ComponentProps } from 'react'
import Icon from './Icon/Icon';

type IconProps = ComponentProps<typeof Icon>;

export function BxBxsBuilding(props: IconProps) {
  return (
    <Icon {...props}><path d="M17 2H7a2 2 0 0 0-2 2v17a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4a2 2 0 0 0-2-2zm-6 14H8v-2h3v2zm0-4H8v-2h3v2zm0-4H8V6h3v2zm5 8h-3v-2h3v2zm0-4h-3v-2h3v2zm0-4h-3V6h3v2z"></path></Icon>
  )
}

export default BxBxsBuilding