import { darken, lighten } from "polished";
import breakpoints from "./breakpoints";

const colors = {
  transparent: "transparent",
  almostBlack: "#111319",
  lightBlack: "#2F3336",
  almostWhite: "#E6E6E6",
  veryDarkBlue: "#08090C",
  slate: "#9BA6B2",
  slateLight: "#DAE1E9",
  slateDark: "#394351",
  smoke: "#F4F7FA",
  smokeLight: "#F9FBFC",
  smokeDark: "#E8EBED",
  white: "#FFF",
  white05: "rgba(255, 255, 255, 0.05)",
  white10: "rgba(255, 255, 255, 0.1)",
  white25: "rgba(255, 255, 255, 0.25)",
  white50: "rgba(255, 255, 255, 0.5)",
  white75: "rgba(255, 255, 255, 0.75)",
  black: "#000",
  black05: "rgba(0, 0, 0, 0.05)",
  black10: "rgba(0, 0, 0, 0.1)",
  black50: "rgba(0, 0, 0, 0.50)",
  primary: "#0E5FAE",
  yellow: "#EDBA07",
  warmGrey: "#EDF2F7",
  searchHighlight: "#FDEA9B",
  danger: "#ff476f",
  dangerLight: "#a2b2c2",
  warning: "#f08a24",
  success: "#2f3336",
  info: "#a0d3e8",
  brand: {
    red: "#FF5C80",
    pink: "#FF4DFA",
    purple: "#9E5CF7",
    blue: "#3633FF",
    marine: "#2BC2FF",
    green: "#42DED1",
    yellow: "#F5BE31",
  },
  tagAlpha: "60",
  tagColors: {
    purple: "#9E5CF7",
    orange: "#FF825C",
    red: "#FF5C80",
    yellow: "#FFBE0B",
    cyan: "#42DED1",
    green: "#00D084",
    pink: "#FF4DFA",
    blue: "#0366d6",
    black: "#2F362F",
    grey: "#4E5C6E",
  },
  editorFontColors: {
    purple: "#9065B0",
    orange: "#D9730F",
    red: "#D44C47",
    yellow: "#CB912F",
    cyan: "#46B2CA",
    green: "#458262",
    pink: "#C14B8A",
    blue: "#347EA9",
    black: "#2F362F",
    grey: "#8D8D8D",
  },
  syncBlockBorderLight: "rgba(30, 144, 255, 0.2)",
  syncBlockBorder: "rgba(30, 144, 255, 0.55)",
  syncBlockBorderAccent: "rgba(30, 144, 255, 1)",
  chartColors: [
    {
      border: "rgb(53, 162, 235)",
      background: "rgba(53, 162, 235, 0.5)",
    },
    {
      border: "rgba(223, 175, 73, 1)",
      background: "rgba(223, 175, 73, 0.5)",
    },
  ],
};

const spacing = {
  padding: "1.5vw 1.875vw",
  vpadding: "1.5vw",
  hpadding: "1.875vw",
  sidebarWidth: 260,
  analyticsWidth: 400,
  sidebarCollapsedWidth: 16,
  sidebarMinWidth: 200,
  sidebarMaxWidth: 400,
  commentsWidth: 385,
  commentsMaxWidth: 600,
  commentsMinWidth: 200,
  commentsCollapsedWidth: 16,
};

export const base = {
  ...colors,
  ...spacing,
  fontFamily: "-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,Oxygen, Ubuntu,Cantarell,'Open Sans','Helvetica Neue',sans-serif",
  fontFamilyMono: "'SFMono-Regular',Consolas,'Liberation Mono', Menlo, Courier,monospace",
  fontWeight: 400,
  fontWeightMedium: 700,
  fontWeightSmall: 300,
  backgroundTransition: "background 100ms ease-in-out",
  selected: colors.primary,
  buttonBackground: colors.primary,
  buttonText: colors.white,
  textHighlight: "#FDEA9B",
  textHighlightForeground: colors.almostBlack,
  code: colors.lightBlack,
  codeComment: "#6a737d",
  codePunctuation: "#5e6687",
  codeNumber: "#d73a49",
  codeProperty: "#c08b30",
  codeTag: "#3d8fd1",
  codeString: "#032f62",
  codeSelector: "#6679cc",
  codeAttr: "#c76b29",
  codeEntity: "#22a2c9",
  codeKeyword: "#d73a49",
  codeFunction: "#6f42c1",
  codeStatement: "#22a2c9",
  codePlaceholder: "#3d8fd1",
  codeInserted: "#202746",
  codeImportant: "#c94922",
  noticeInfoBackground: colors.primary,
  noticeInfoText: colors.almostBlack,
  noticeTipBackground: "#F5BE31",
  noticeTipText: colors.almostBlack,
  noticeWarningBackground: "#d73a49",
  noticeWarningText: colors.almostBlack,
  selectedBlock: "rgb(226, 238, 251)",
  defaultBorderRadius: "4px",
  breakpoints,
};

export const light = {
  ...base,
  isDark: false,
  background: colors.white,
  secondaryBackground: colors.warmGrey,
  link: colors.primary,
  cursor: colors.almostBlack,
  text: colors.almostBlack,
  textSecondary: colors.slateDark,
  textTertiary: colors.slate,
  placeholder: "#a2b2c3",
  sidebarBackground: colors.warmGrey,
  sidebarActiveBackground: "#d7e0ea",
  sidebarControlHoverBackground: "rgb(138 164 193 / 20%)",
  sidebarDraftBorder: darken("0.25", colors.warmGrey),
  sidebarText: "rgb(78, 92, 110)",
  backdrop: "rgba(0, 0, 0, 0.2)",
  shadow: "rgba(0, 0, 0, 0.2)",

  modalBackdrop: colors.black10,
  modalBackground: colors.white,
  modalShadow: "0 4px 8px rgb(0 0 0 / 8%), 0 2px 4px rgb(0 0 0 / 0%), 0 30px 40px rgb(0 0 0 / 8%)",

  textDiffInserted: colors.almostBlack,
  textDiffInsertedBackground: "rgba(18, 138, 41, 0.16)",
  textDiffDeleted: colors.slateDark,
  textDiffDeletedBackground: "#ffebe9",

  menuItemSelected: colors.warmGrey,
  menuBackground: colors.white,
  menuShadow: "0 0 0 1px rgb(0 0 0 / 2%), 0 4px 8px rgb(0 0 0 / 8%), 0 2px 4px rgb(0 0 0 / 0%), 0 30px 40px rgb(0 0 0 / 8%)",
  headerShadow: "0 0 0 1px rgb(0 0 0 / 2%), 0 4px 8px rgb(0 0 0 / 8%), 0 2px 4px rgb(0 0 0 / 0%), 0 30px 40px rgb(0 0 0 / 3%)",
  textCommentShadow: "0px 3px 2px rgba(15, 15, 15, 0.04), 0px 3px 9px rgba(15, 15, 15, 0.08)",
  divider: colors.slateLight,
  titleBarDivider: colors.slateLight,
  inputBorder: colors.slateLight,
  inputBorderFocused: colors.slate,
  listItemHoverBackground: colors.warmGrey,
  mentionBackground: colors.warmGrey,
  toolbarHoverBackground: colors.slate,
  toolbarBackground: colors.white,
  toolbarInput: colors.black10,
  toolbarItem: colors.lightBlack,
  tableDivider: colors.smokeDark,
  tableSelected: colors.primary,
  tableSelectedBackground: "#E5F7FF",
  tableHeaderBackground: colors.white,
  buttonNeutralBackground: colors.white,
  buttonNeutralText: colors.almostBlack,
  buttonNeutralBorder: darken(0.15, colors.white),
  tooltipBackground: colors.almostBlack,
  tooltipText: colors.white,
  toastBackground: colors.almostBlack,
  toastText: colors.white,
  quote: colors.slateLight,
  codeBackground: colors.smoke,
  codeBorder: colors.smokeDark,
  embedBorder: colors.slateLight,
  horizontalRule: colors.slateLight, // colors.smokeDark,
  progressBarBackground: colors.slateLight,
  scrollbarBackground: colors.smoke,
  scrollbarThumb: darken(0.15, colors.smokeDark),
  imageBorder: "rgba(17, 19, 25, 0.7)",
  imageShadow: "rgba(54, 80, 100, 0.6)",
  resizeBackground: "rgba(50, 50, 50, 0.1)",
  columnBorder: "rgba(50, 50, 50, 0.3)",
  columnHeader: "rgba(245, 245, 245, 1)",
  pickerInputBackground: colors.warmGrey,
  greyBackgroundBadge: "rgb(237, 242, 247)",
  pickerInputSeparator: darken(0.05, colors.warmGrey),
  databaseCardPreviewBackground: darken(0.05, colors.white),
  // calendar start
  calendarOffRange: colors.warmGrey,
  calendarBorder: colors.warmGrey,
  calendarResizeAnchor: darken("0.05", colors.warmGrey),
  calendarEventBackground: colors.white,
  calendarEventHoverBackground: colors.white,
  peopleOptionBackground: colors.warmGrey,
  // uikit
  buttonDangerHoverBackground: "#ff2d5b",
  buttonPrimaryBackground: colors.primary,
  buttonPrimaryHoverBackground: "rgb(19, 117, 211)",
  buttonNeutralHoverBackground: "#f2f2f2",
  buttonNeutralBorderColor: "#d9d9d9",
  buttonNeutralColor: colors.almostBlack,
  buttonNeutralHoverColor: colors.white,
  buttonLinkHoverColor: "rgb(19, 117, 211)",
  buttonDisabledBackground: colors.slateLight,
  buttonDisabledColor: darken(0.3, colors.slate),
  buttonBoxShadow: "rgba(0, 0, 0, 0.2) 0 1px 2px",
  buttonColor: colors.white,
  buttonHoverColor: colors.white,
  calendarShadow: "rgb(237, 242, 247) 0px 0px 0px 1px, rgb(218, 225, 233) 0px 2px 4px",
  textCommentBorder: "#d9d9d9",
  textCommentHover: "#f2f2f2",
  editorBackgroundColors: {
    purple: "#F7F3FA",
    orange: "#FBECDD",
    red: "#FDEBEC",
    yellow: "#FBF3DB",
    cyan: "#EEFCFF",
    green: "#EDF3EB",
    pink: "#FAF1F5",
    blue: "#E7F3F8",
    black: "#E8E8E8",
    grey: "#F1F1EF",
  }
};

export const dark = {
  ...base,
  isDark: true,
  background: colors.almostBlack,
  secondaryBackground: colors.black50,
  link: "#137FFB",
  text: colors.almostWhite,
  cursor: colors.almostWhite,
  textSecondary: lighten(0.1, colors.slate),
  textTertiary: colors.slate,
  placeholder: colors.slateDark,
  sidebarBackground: colors.veryDarkBlue,
  sidebarActiveBackground: lighten(0.02, colors.almostBlack),
  sidebarControlHoverBackground: colors.white10,
  sidebarDraftBorder: darken("0.35", colors.slate),
  sidebarText: colors.slate,
  backdrop: "rgba(255, 255, 255, 0.3)",
  shadow: "rgba(0, 0, 0, 0.6)",

  modalBackdrop: colors.black50,
  modalBackground: "#1f2128",
  modalShadow: "0 0 0 1px rgba(0, 0, 0, 0.1), 0 8px 16px rgba(0, 0, 0, 0.3), 0 2px 4px rgba(0, 0, 0, 0.08)",

  textDiffInserted: colors.almostWhite,
  textDiffInsertedBackground: "rgba(63,185,80,0.3)",
  textDiffDeleted: darken(0.1, colors.almostWhite),
  textDiffDeletedBackground: "rgba(248,81,73,0.15)",

  menuItemSelected: lighten(0.1, "#1f2128"),
  menuBackground: "#1f2128",
  menuShadow: "0 0 0 1px rgba(0, 0, 0, 0.1), 0 8px 16px rgba(0, 0, 0, 0.3), 0 2px 4px rgba(0, 0, 0, 0.08)",
  headerShadow: "0 0 0 1px rgba(0, 0, 0, 0.1), 0 8px 16px rgba(0, 0, 0, 0.3), 0 2px 4px rgba(0, 0, 0, 0.08)",
  textCommentShadow: "0px 3px 2px rgba(15, 15, 15, 0.04), 0px 3px 9px rgba(15, 15, 15, 0.08)",
  divider: lighten(0.1, colors.almostBlack),
  titleBarDivider: darken(0.4, colors.slate),
  inputBorder: colors.slateDark,
  inputBorderFocused: colors.slate,
  listItemHoverBackground: colors.white10,
  mentionBackground: colors.white10,
  toolbarHoverBackground: colors.slateDark,
  toolbarBackground: colors.slateDark,
  toolbarInput: colors.white10,
  toolbarItem: colors.white,
  tableDivider: colors.lightBlack,
  tableSelected: colors.primary,
  tableSelectedBackground: "#002333",
  tableHeaderBackground: colors.almostBlack,
  buttonNeutralBackground: colors.almostBlack,
  buttonNeutralText: colors.white,
  buttonNeutralBorder: colors.slateDark,
  tooltipBackground: colors.white,
  tooltipText: colors.lightBlack,
  toastBackground: colors.white,
  toastText: colors.lightBlack,
  quote: colors.almostWhite,
  code: colors.almostWhite,
  codeBackground: colors.black,
  codeBorder: colors.black50,
  codeString: "#3d8fd1",
  embedBorder: colors.black50,
  horizontalRule: colors.white25, // lighten(0.1, colors.almostBlack),
  noticeInfoBackground: "#25349b", // "#252a37",
  noticeInfoText: colors.white,
  noticeTipBackground: "#a3840a",
  noticeTipText: colors.white,
  noticeWarningBackground: "#ac001b",
  noticeWarningText: colors.white,
  selectedBlock: "rgb(59, 70, 81)",
  progressBarBackground: colors.slate,
  scrollbarBackground: colors.black,
  scrollbarThumb: colors.lightBlack,
  imageBorder: "rgba(230, 230, 230, 0.7)",
  imageShadow: "rgba(230, 230, 230, 0.2)",
  resizeBackground: "rgba(255, 255, 255, 0.2)",
  columnBorder: "rgba(255, 255, 255, 0.3)",
  columnHeader: "rgba(245, 245, 245, 0.15)",
  greyBackgroundBadge: "#626366",
  pickerInputBackground: colors.almostBlack,
  pickerInputSeparator: lighten(0.05, colors.almostBlack),
  databaseCardPreviewBackground: lighten(0.1, "#1f2128"),
  // calendar start
  calendarOffRange: colors.white10,
  calendarBorder: colors.white10,
  calendarResizeAnchor: lighten(0.09, colors.almostBlack),
  calendarEventBackground: lighten(0.06, colors.almostBlack),
  calendarEventHoverBackground: lighten(0.09, colors.almostBlack),
  calendarShadow: "none",
  peopleOptionBackground: colors.tagColors.grey,
  // uikit
  buttonDangerHoverBackground: "#ff2d5b",
  buttonPrimaryBackground: colors.primary,
  buttonPrimaryHoverBackground: "#035abd",
  buttonNeutralHoverBackground: "#07070a",
  buttonNeutralBorderColor: "#394352",
  buttonNeutralColor: colors.slate,
  buttonNeutralHoverColor: colors.white,
  buttonLinkHoverColor: "#024ea4",
  buttonDisabledBackground: lighten(0.2, colors.lightBlack),
  buttonDisabledColor: colors.slate,
  buttonBoxShadow: "rgba(0, 0, 0, 0.2) 0 1px 2px",
  buttonColor: colors.white,
  textCommentBorder: "#394352",
  textCommentHover: "#07070a",
  editorBackgroundColors: {
    purple: "#3C2D49",
    orange: "#5C3B23",
    red: "#522E2A",
    yellow: "#564328",
    cyan: "#12424D",
    green: "#243D30",
    pink: "#4E2C3C",
    blue: "#143A4E",
    black: "#1C2021",
    grey: "#2F2F2F",
  }
};

export const lightMobile = light;

export const darkMobile = {
  ...dark,
  background: colors.black,
  codeBackground: colors.almostBlack,
};

export default light;
