import { addDays, differenceInDays } from "date-fns";
import i18n from "i18next";
import { floor } from "lodash";
import { action, autorun, computed, makeObservable, observable, override } from "mobx";
import { DocumentType } from "@shared/types";
import parseTitle from "@shared/utils/parseTitle";
import unescape from "@shared/utils/unescape";
import DocumentsStore from "~/stores/DocumentsStore";
import User from "~/models/User";
import Field from "~/models/decorators/Field";
import type { NavigationNode } from "~/types";
import { client } from "~/utils/ApiClient";
import Storage from "~/utils/Storage";
import ParanoidModel from "./ParanoidModel";
import View from "./View";
import PropertyModel from "@database/stores/PropertyModel";

type SaveOptions = {
  publish?: boolean;
  done?: boolean;
  autosave?: boolean;
  lastRevision?: number;
};

type DocumentCoverPreviewTop = {
  top: string;
}

export type DocumentCoverPreview = {
  property_file?: DocumentCoverPreviewTop;
  document_cover?: DocumentCoverPreviewTop;
}

export type DocumentCover = {
  url?: string,
  top?: number | string,
  isDefault?: boolean;
  preview?: DocumentCoverPreview;
}

export type DocumentTasks = {
  completed: number;
  total: number;
}

export default class Document extends ParanoidModel {
  constructor(fields: Record<string, any>, store: DocumentsStore) {
    super(fields, store);

    if (this.isPersistedOnce && this.isFromTemplate) {
      this.title = "";
    }

    this.embedsDisabled = Storage.get(`embedsDisabled-${fields.id}`) ?? false;

    autorun(() => {
      Storage.set(
        `embedsDisabled-${this.id}`,
        this.embedsDisabled ? true : undefined
      );
    });

    Object.assign(this, fields);
    makeObservable(this);
  }

  @override
  isSaving = false;

  @observable
  embedsDisabled: boolean;

  @observable
  lastViewedAt: string | undefined;

  store: DocumentsStore;

  @Field
  @observable
  documentChangesSubscriptionId: string;

  @observable
  calendarChangesSubscriptionId: string | null = null;

  @Field
  @observable
  collectionId: string;

  @Field
  @override
  id: string;

  @Field
  @observable
  type: DocumentType;

  @Field
  @observable
  text: string;

  @Field
  @observable
  title: string;

  @Field
  @observable
  icon: string;

  @Field
  @observable.deep
  properties: PropertyModel<unknown>[];

  @Field
  @observable
  tableOrder: number;

  @Field
  @observable
  template: boolean;

  @Field
  @observable
  fullWidth: boolean;

  @Field
  @observable
  templateId: string | undefined;

  @Field
  @observable
  parentDocumentId: string | undefined;

  @Field
  @observable
  version: number;

  @Field
  @observable
  cover: DocumentCover | null;

  @observable
  propertyConfig: PropertyModel[];

  @observable
  displayConfig: Record<string, any> | null = null;

  collaboratorIds: string[];

  createdBy: User;

  updatedBy: User;

  @observable
  publishedAt: string | undefined;

  @observable
  savingInProgress: boolean;

  @observable
  tasks: DocumentTasks;

  archivedAt: string;

  url: string;

  urlId: string;

  revision: number;

  @computed
  get emoji() {
    const { emoji } = parseTitle(this.title);
    return emoji;
  }

  /**
   * Best-guess the text direction of the document based on the language the
   * title is written in. Note: wrapping as a computed getter means that it will
   * only be called directly when the title changes.
   */
  @computed
  get dir(): "rtl" | "ltr" {
    const element = document.createElement("p");
    element.innerText = this.title;
    element.style.visibility = "hidden";
    element.dir = "auto";

    // element must appear in body for direction to be computed
    document.body?.appendChild(element);
    const direction = window.getComputedStyle(element).direction;
    document.body?.removeChild(element);

    return direction === "rtl" ? "rtl" : "ltr";
  }

  @computed
  get noun(): string {
    return this.template ? "template" : "document";
  }

  @computed
  get isOnlyTitle(): boolean {
    return !this.text.trim();
  }

  @computed
  get modifiedSinceViewed(): boolean {
    return !!this.lastViewedAt && this.lastViewedAt < this.updatedAt;
  }

  @computed
  get isBadgedNew(): boolean {
    return (
      !this.lastViewedAt &&
      differenceInDays(new Date(), new Date(this.createdAt)) < 14
    );
  }

  @computed
  get isStarred(): boolean {
    return !!this.store.rootStore.stars.orderedData.find(
      (star) => star.documentId === this.id
    );
  }

  @computed
  get isArchived(): boolean {
    return !!this.archivedAt;
  }

  @computed
  get isDeleted(): boolean {
    return !!this.deletedAt;
  }

  @computed
  get isTemplate(): boolean {
    return !!this.template;
  }

  @computed
  get isDraft(): boolean {
    return !this.publishedAt;
  }

  @computed
  get hasEmptyTitle(): boolean {
    return this.title === "";
  }

  @computed
  get titleWithDefault(): string {
    return this.title || i18n.t("Untitled");
  }

  @computed
  get permanentlyDeletedAt(): string | undefined {
    if (!this.deletedAt) {
      return undefined;
    }

    return addDays(new Date(this.deletedAt), 30).toString();
  }

  @computed
  get isPersistedOnce(): boolean {
    return this.createdAt === this.updatedAt;
  }

  @computed
  get isFromTemplate(): boolean {
    return !!this.templateId;
  }

  @computed
  get isTasks(): boolean {
    return !!this.tasks.total;
  }

  @computed
  get tasksPercentage(): number {
    if (!this.isTasks) {
      return 0;
    }

    return floor((this.tasks.completed / this.tasks.total) * 100);
  }

  @action.bound
  setSavingInProgress(state: boolean) {
    this.savingInProgress = state;
  }

  @action.bound
  updateTasks(total: number, completed: number) {
    if (total !== this.tasks.total || completed !== this.tasks.completed) {
      this.tasks = { total, completed };
    }
  }

  @action.bound
  share = async () => {
    return this.store.rootStore.shares.create({
      documentId: this.id,
    });
  };

  @action.bound
  archive = () => {
    return this.store.archive(this);
  };

  @action.bound
  restore = (options?: { revisionId?: string; collectionId?: string }) => {
    return this.store.restore(this, options);
  };

  @action.bound
  unpublish = () => {
    return this.store.unpublish(this);
  };

  @action.bound
  enableEmbeds = () => {
    this.embedsDisabled = false;
  };

  @action.bound
  disableEmbeds = () => {
    this.embedsDisabled = true;
  };

  @action.bound
  async subscribeToCalendarChanges() {
    const res = await client.post(`/subscriptions.create`, {
      documentId: this.id,
      event: "calendar.events"
    });
    this.calendarChangesSubscriptionId = res.data.id
  }

  @action.bound
  async unsubscribeFromCalendarChanges() {
    await client.post(`/subscriptions.delete`, {
      id: this.calendarChangesSubscriptionId,
      event: "calendar.events"
    });
    this.calendarChangesSubscriptionId = null;
  }

  async checkCalendarSubscription() {
    try {
      const resp = await client.post(`/subscriptions.info`, {
        documentId: this.id,
        event: "calendar.events",
      });
      this.calendarChangesSubscriptionId = resp.data.id;
    } catch {
      this.calendarChangesSubscriptionId = null;
    }
  }

  @action.bound
  subscribeToDocumentChanges = async () => {
    const res = await client.post(`/subscriptions.create`, {
      documentId: this.id,
      event: "documents.update"
    });
    this.documentChangesSubscriptionId = res.data.id;
  };

  @action.bound
  unsubscribeToDocumentChanges = async () => {
    await client.post(`/subscriptions.delete`, {
      id: this.documentChangesSubscriptionId
    });
    this.documentChangesSubscriptionId = "";
  };

  @action.bound
  pin = async (collectionId?: string) => {
    await this.store.rootStore.pins.create({
      documentId: this.id,
      ...(collectionId ? { collectionId } : {}),
    });
  };

  @action.bound
  unpin = async (collectionId?: string) => {
    const pin = this.store.rootStore.pins.orderedData.find(
      (pin) =>
        pin.documentId === this.id &&
        (pin.collectionId === collectionId ||
          (!collectionId && !pin.collectionId))
    );

    await pin?.delete();
  };

  @action.bound
  star = async () => {
    return this.store.star(this);
  };

  @action.bound
  unstar = async () => {
    return this.store.unstar(this);
  };

  @action
  view = () => {
    // we don't record views for documents in the trash
    if (this.isDeleted) {
      return;
    }

    this.lastViewedAt = new Date().toString();

    return this.store.rootStore.views.create({
      documentId: this.id,
    });
  };

  @action
  updateLastViewed = (view: View) => {
    this.lastViewedAt = view.lastViewedAt;
  };

  @action
  templatize = async () => {
    return this.store.templatize(this.id);
  };

  @action.bound
  setCover = (cover: DocumentCover | null) => {
    this.cover = cover;
  }

  @action.bound
  setIcon = (icon: string) => {
    this.icon = icon;
  }

  @action.bound
  setTitle = (title: string) => {
    this.title = title;
  }

  @action.bound
  setPropertyValue = (propertyId: string, value: unknown) => {
    if (!this.properties) {
      // FIXME: Types not compatible
      this.properties = {} as any;
    }
    this.properties = { ...this.properties, [propertyId]: value };
  }

  @override
  save = async (options?: SaveOptions | undefined) => {
    const params = this.toAPI();
    const collaborativeEditing = this.store.rootStore.auth.team
      ?.collaborativeEditing;

    if (collaborativeEditing) {
      delete params.text;
    }

    this.isSaving = true;

    try {
      const model = await this.store.save(
        { ...params, id: this.id },
        {
          lastRevision: options?.lastRevision || this.revision,
          ...options,
        }
      );

      // if saving is successful set the new values on the model itself
      Object.assign(this, { ...params, ...model })

      this.persistedAttributes = this.toAPI();

      return model;
    } finally {
      this.isSaving = false;
    }
  };

  move = (collectionId: string, parentDocumentId?: string | undefined) => {
    return this.store.move(this.id, collectionId, parentDocumentId);
  };

  duplicate = (duplicateTitlePostfix: string) => {
    return this.store.duplicate(this, duplicateTitlePostfix);
  };

  getSummary = (paragraphs = 4) => {
    return this.getParagraphs(paragraphs).join("\n");
  };

  getParagraphs = (paragraphs = 4) => {
    return this.text.trim().split("\n").slice(0, paragraphs);
  }

  @computed
  get pinned(): boolean {
    return !!this.store.rootStore.pins.orderedData.find(
      (pin) =>
        pin.documentId === this.id && pin.collectionId === this.collectionId
    );
  }

  @computed
  get pinnedToHome(): boolean {
    return !!this.store.rootStore.pins.orderedData.find(
      (pin) => pin.documentId === this.id && !pin.collectionId
    );
  }

  @computed
  get isActive(): boolean {
    return !this.isDeleted && !this.isTemplate && !this.isArchived;
  }

  @computed
  get asNavigationNode(): NavigationNode {
    return {
      id: this.id,
      title: this.title,
      children: this.store.orderedData
        .filter((doc) => doc.parentDocumentId === this.id && doc.type !== DocumentType.Row)
        .map((doc) => doc.asNavigationNode),
      url: this.url,
      isDraft: this.isDraft,
      type: this.type,
    };
  }

  download = async () => {
    // Ensure the document is upto date with latest server contents
    await this.fetch();
    const body = unescape(this.text);
    const blob = new Blob([`# ${this.title}\n\n${body}`], {
      type: "text/markdown",
    });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");

    if (typeof a.download === undefined) {
      return alert('Your browser don\'t support downloading');
    }
    // Firefox support requires the anchor tag be in the DOM to trigger the dl
    if (document.body) {
      document.body.appendChild(a);
    }
    a.href = url;
    a.download = `${this.titleWithDefault}.md`;
    a.click();
  };

  downloadCSV = async () => {
    if (this.type !== DocumentType.Database) {
      throw new Error("Cannot request CSV for document type other than database");
    }

    return client.post(
      "/database.export_csv",
      {
        databaseId: this.id
      }, {
        download: true
      }
    );
  };
}
