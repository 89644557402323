import {observer} from "mobx-react";
import {useEffect} from "react";
import {useTranslation} from "react-i18next";
import useStores from "~/hooks/useStores";
import useToasts from "~/hooks/useToasts";

const FileDownloadController = () => {
  const { t } = useTranslation();
  const { showToast } = useToasts();
  const { downloadList, fileOperations } = useStores();

  const states = fileOperations.exports.map(({ id, state }) => ({ id, state }));
  const statesCount = states.length;

  const ids = downloadList.ids;
  const downloadCount = ids.length;

  useEffect(() => {
    if (downloadCount && statesCount) {
      states.forEach(({ id, state }) => {
        if (ids.includes(id)) {
          if(state === "complete") {
            const a = createAnchor(id);

            document.body.appendChild(a);
            a.click();
            a.remove();
            downloadList.removeOperationId(id);
          }
          if (state === "expired" || state === "error") {
            showToast(t("Failed to download file"));
            downloadList.removeOperationId(id);
          }
        }
      });
    }
  }, [downloadCount, downloadList, ids, showToast, states, statesCount, t]);

  return null;
}

function createAnchor(id: string) {
  const link = document.createElement("a");
  link.href = `/api/fileOperations.redirect?id=${id}`;
  link.target = "_blank";
  link.rel = "noopener noreferrer";

  return link;
}

export default observer(FileDownloadController);
