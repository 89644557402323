import i18next from "i18next";
import { action, makeObservable } from "mobx";
import { ProviderType } from "@shared/types";
import { LdapData } from "~/stores/ProviderLDAPStore";
import { OidcData } from "~/stores/ProviderOIDCStore";
import { SamlData } from "~/stores/ProviderSAMLStore";
import { client } from "~/utils/ApiClient";

export enum ProviderActions {
  CREATE = "create",
  UPDATE = "update",
  PING = "ping",
}

export type CommonData = {
  name: string;
  id?: string;
  isDefault?: boolean;
  isActive?: boolean;
}

export type ProviderData = LdapData | SamlData | OidcData;

export type ResponseNotify = {
  type: string;
  value?: boolean;
  text?: string;
  userCount?: number | null;
}

export default abstract class BaseProviderStore {
  ENDPOINT: ProviderType;
  action: ProviderActions;
  data: ProviderData;
  DEFAULT_BUTTON_NAME: string;

  protected constructor() {
    makeObservable(this);
  }

  @action
  getFormData(_action: ProviderActions): FormData | ProviderData {
    return {
      ...this.data,
      id: this.data.id || undefined,
      isDefault: this.data.isDefault || false,
      isActive: this.data.isActive || false,
      customButtonName: this.data.customButtonName || this.DEFAULT_BUTTON_NAME,
    };
  }

  responseMap(response: ProviderData, _action: ProviderActions): ProviderData | ResponseNotify {
    if (!response) {
      return {
        type: "error",
      }
    }

    return {
      type: "success",
      text: i18next.t("Saved successfully!"),
    }
  }

  @action
  async createProvider() {
    try {
      const res = await client.post(`/${this.ENDPOINT}.create`, this.getFormData(ProviderActions.CREATE));
      if (res.data.provider.id) {
        this.data.id = res.data.provider.id;
      }
      return this.responseMap(res, ProviderActions.CREATE);
    } catch (e) {
      throw new Error("Failed to save provider");
    }
  }

  @action
  async updateProvider() {
    try {
      const res = await client.post(`/${this.ENDPOINT}.update`, this.getFormData(ProviderActions.UPDATE));
      return this.responseMap(res, ProviderActions.UPDATE);
    } catch (e) {
      throw new Error("Failed to update provider");
    }
  }

  @action
  async request(): Promise<ProviderData | ResponseNotify | undefined> {
    switch (this.action) {
      case ProviderActions.CREATE:
        return this.createProvider();
      case ProviderActions.UPDATE:
        return this.updateProvider();
      default:
        return undefined;
    }
  }
}