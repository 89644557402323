import React, { ComponentProps } from 'react'
import Icon from "./Icon/Icon";

type IconProps = ComponentProps<typeof Icon>;

export function IcBaselineOpenInBrowser(props: IconProps) {
  return (
    <Icon {...props}><path  d="M19 4H5a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h4v-2H5V8h14v10h-4v2h4c1.1 0 2-.9 2-2V6a2 2 0 0 0-2-2zm-7 6l-4 4h3v6h2v-6h3l-4-4z"></path></Icon>
  )
}

export default IcBaselineOpenInBrowser