import React, { ComponentProps } from "react";
import Icon from "./Icon/Icon";

type IconProps = ComponentProps<typeof Icon>;

export function MdiPublish(props: IconProps) {
  return (
    <Icon {...props}>
      <path d="M5 4v2h14V4H5m0 10h4v6h6v-6h4l-7-7l-7 7Z"></path>
    </Icon>
  );
}

export default MdiPublish;
