import { action, makeObservable, observable } from "mobx";
import { COMMENTS_PAGINATION_LIMIT } from "~/scenes/Document/components/TextComments/constants";
import { client } from "~/utils/ApiClient";

export type Comment = {
  id: string;
  entityType: "document";
  entityId: string;
  text: string;
  quote: string | null;
  threadId: string | null;
  teamId: string;
  createdById: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
  isResolved: boolean;
}

class TextCommentsStore {

  @observable
  public data = observable.map(new Map<string, Comment>());

  constructor() {
    makeObservable(this);
  }

  @action.bound
  private updateData(comments: Comment[]) {
    comments.forEach((comment) => this.data.set(comment.id, comment));
  }

  @action.bound
  public async getList(documentId: string, threadId?: string) {
    const result: Comment[] = [];

    let offset = 0;
    let count = 0;

    do {
      const raw = await client.post("/comments.list", {
        entityType: "document",
        entityId: documentId,
        paranoid: true,
        threadId,
        offset,
        limit: COMMENTS_PAGINATION_LIMIT
      });

      count = raw.count;
      result.push(...raw.comments);
      offset += COMMENTS_PAGINATION_LIMIT;
    } while(result.length < count);

    this.updateData(result);
    return result;
  }

  @action.bound
  async create(documentId: string, text: string, parentCommentId?: string, quote?: string) {
    const raw = await client.post("/comments.create", {
      entityType: "document",
      entityId: documentId,
      parentCommentId,
      quote,
      text,
    });

    this.data.set(raw.comment.id, raw.comment);

    return raw.comment;
  }

  @action.bound
  async update(comment: Comment) {
    const raw = await client.post("/comments.update", {
      id: comment.id,
      text: comment.text
    });

    this.data.set(raw.comment.id, raw.comment);

    return raw.comment;
  }

  @action.bound
  async setResolve(comment: Comment, isResolved: boolean) {
    const raw = await client.post("/comments.resolve", {
      id: comment.id,
      isResolved,
    });

    this.data.set(raw.data.id, raw.data);

    return raw.data;
  }

  @action.bound
  async delete(comment: Comment) {
    await client.post("/comments.delete", { id: comment.id });
    this.data.delete(comment.id);
  }

  @action.bound
  public async get(id: string, force = false) {
    const comment = this.data.get(id);
    if (comment && !force) {
      return comment;
    }

    const raw = await client.post("/comments.info", {
      paranoid: true,
      id
    });

    this.data.set(id, raw.data);
    return raw.data;
  }

  @action.bound
  public async getThread(id: string, force = false) {
    // Получаем тред с бэкенда
    if (force) {
      const result: Comment[] = [];

      let offset = 0;
      let count = 0;

      do {
        const raw = await client.post("/comments.thread", {
          paranoid: true,
          limit: COMMENTS_PAGINATION_LIMIT,
          offset,
          id,
        });

        count = raw.data.count;
        result.push(...raw.data.thread);
        offset += COMMENTS_PAGINATION_LIMIT;
      } while (result.length < count);

      this.updateData(result);
      return result;
    }

    // Строим тред на основе уже загруженных данных
    return Array
      .from(this.data.values())
      .filter((comment) => {
        return comment.threadId === id;
      })
      .sort((a, b) => {
        return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
      });
  }
}

export default TextCommentsStore;
