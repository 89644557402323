import { motion } from "framer-motion";
import React, { PropsWithChildren, CSSProperties, ReactElement } from "react";
import styled from "styled-components";
import breakpoints from "@shared/styles/breakpoints";
import useMobile from "~/hooks/useMobile";
import { useVh } from "~/hooks/useVh";
import { StyledWithVh } from "~/types";
import Header from "@uikit/Modal/CenteredModal/Header";
import { MOTION_PARAMS } from "@uikit/Modal/constants";

type Props = {
  onRequestClose: () => void;
  title: ReactElement | string;
  style: CSSProperties;
  showCloseButton: boolean;
  className?: string;
}

const CenteredModal = ({
  onRequestClose,
  title,
  style,
  showCloseButton,
  className,
  children,
}: PropsWithChildren<Props>) => {
  const isMobile = useMobile();
  const isHeader = Boolean(title || showCloseButton);
  const vh = useVh();

  return (
    <CenteredModalWrapper
      {...MOTION_PARAMS}
      isMobile={isMobile}
      vh={vh}
      style={style}
      className={className}
    >
      {isHeader && (
        <Header
          title={title}
          onRequestClose={onRequestClose}
          showCloseButton={showCloseButton}
        />
      )}
      <div>
        {children}
      </div>
    </CenteredModalWrapper>
  );
}

const CenteredModalWrapper = motion(styled.div<StyledWithVh & { isMobile: boolean }>`
  min-width: 350px;
  max-width: 30vw;
  max-height: ${({ vh }) => `${100 * vh - 100}px`};
  box-sizing: border-box;
  
  padding: 24px;
  
  border-radius: 8px;

  background-color: ${(props) => props.theme.background};
  box-shadow: ${(props) => props.theme.modalShadow};

  @media (max-width: ${breakpoints.tablet}px) {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    max-height: unset;
    max-width: unset;
    border-radius: unset;
  }
`);

export default CenteredModal;
