import React, { ComponentProps } from "react";
import Icon from "./Icon/Icon";

type IconProps = ComponentProps<typeof Icon>;

export function CiHeadingH1(props: IconProps) {
  return (
    <Icon size={18} {...props}>
      <path d="M10 4v7H4V4H2v16h2v-7h6v7h2V4h-2Zm10 16V8h-1.5l-2.5.67v2.07l2-.536V20h2Z"></path>
    </Icon>
  );
}

export default CiHeadingH1;
