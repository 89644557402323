import React, { ComponentProps } from "react";
import Icon from "./Icon/Icon";

type IconProps = ComponentProps<typeof Icon>;

export function IcBaselineArrowForwardIos(props: IconProps) {
  return (
    <Icon {...props}>
      <path d="M6.23 20.23L8 22l10-10L8 2L6.23 3.77L14.46 12z"></path>
    </Icon>
  );
}

export default IcBaselineArrowForwardIos;
