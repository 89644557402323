// Размер страницы комментариев
export const COMMENTS_PAGINATION_LIMIT = 100;

// Ширина ленты комментариев для полного их отображения
export const RIBBON_FULL_WIDTH = 320;

// Ширина ленты комментариев для отображения их в виде иконок
export const RIBBON_SMALL_WIDTH = 50;

// Граница ширины контейнера документа, при пересечении которой происходит смена вида ленты комментариев
export const CONTAINER_WIDTH_LIMIT = 1000;

// Высота контейнера для свернутого комментария в ленте с полным отображением
export const COLLAPSED_COMMENT_HEIGHT = 100;

// Высота контейнера для ввода нового комментария в ленте с полным отображением
export const NEW_COMMENT_HEIGHT = 130;

// Высота контейнера развернутого комментария в ленте с полным отображением
export const EXPANDED_COMMENT_HEIGHT = 400;

// Минимальное расстояние между комментариями в ленте с полным отображением
export const FULL_RIBBON_GAP = 10;

// Если метка комментария расположена на экране выше этой границы, то при клике по контейнеру
// комментария, соответствующему метке - окно будет прокручено к ней
export const MARK_TOP_SCROLL_BORDER = 100;

// Параметры модального окна для упоминаний в комментариях
export const MENTION_MODAL_WIDTH = 300;

export const MENTION_MODAL_HEIGHT = 350;
