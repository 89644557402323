import styled, { css, DefaultTheme } from "styled-components";

export type TFontSize = "large" | "medium" | "small" | "xsmall" | "xxsmall";

export type TTextType = "secondary" | "tertiary" | "neutral";

type TFontWeight = 'normal' | 'medium' | 'small' | number;

type Props = {
  align?: "start" | "end" | "left" | "right" | "center";
  type?: TTextType;
  transform?: "capitalize" | "uppercase" | "lowercase" | "none";
  wrap?: 'none' | 'nowrap';
  size?: TFontSize;
  weight?: TFontWeight;
  noMargin?: boolean;
};

/**
 * Use this component for all interface text that should not be selectable
 * by the user, this is the majority of UI text explainers, notes, headings.
 */
const Text = styled.p<Props>`
  margin-top: 0;
  ${({ noMargin }) => noMargin && css`margin: 0;`};
  text-align: ${({ align }) => align || "start"};
  color: ${({ type, theme }) => getColor(type, theme)};
  font-size: ${({ size }) => getCSSFontSize(size)};
  white-space: ${({ wrap }) => wrap === 'nowrap' ? 'nowrap' : 'normal'};
  user-select: none;
  text-transform: ${({ transform }) => transform || "none"};
  font-weight: ${({ weight, theme }) => getFontWeight(weight, theme)}
`;

export function getFontSize(size: TFontSize): number {
  switch (size) {
    case "large":
      return 18;
    case "medium":
      return 16;
    case "small":
      return 14;
    case "xsmall":
      return 13;
    case "xxsmall":
      return 12;
    default:
      console.warn("Некорректный пресет размера шрифта");
      return 14;
  }
}

export function getCSSFontSize(size: TFontSize | undefined) {
  if (size) {
    return `${getFontSize(size)}px`;
  }

  return "inherit";
}

function getColor(type: TTextType | undefined, theme: DefaultTheme) {
  switch(type) {
    case "secondary":
      return theme.textSecondary;
    case "tertiary":
      return theme.textTertiary;
    case "neutral":
      return theme.sidebarText;
    default:
      return theme.text;
  }
}

function getFontWeight(weight: TFontWeight | undefined, theme: DefaultTheme) {
  if (typeof weight === "number") {
    return weight;
  } else {
    switch (weight) {
      case 'medium':
        return theme.fontWeightMedium;
      case 'small':
        return theme.fontWeightSmall;
      case 'normal':
      default:
        return "inherit";
    }
  }
}

export default Text;
