import * as React from "react";
import Frame from "../components/Frame";
import { EmbedProps as Props } from ".";

const BASE_REGEX = /(?:https?:\/\/)?(?:www\.)?rutube\.ru\/video\/([a-zA-Z\d]+)\/?(\?.*)?$/i;

// Регулярное выражение для трансляций
const LIVE_REGEX = /(?:https?:\/\/)?(?:www\.)?rutube\.ru\/live\/video\/([a-zA-Z\d]+)\/?(\?.*)?$/i;

// Регулярное выражение для видео, доступных только по ссылке
const PRIVATE_REGEX = /(?:https?:\/\/)?(?:www\.)?rutube\.ru\/video\/([a-zA-Z0-9_-]+)\/([a-zA-Z\d]+)\/?(\?.*)?$/i;

const EMBED_REGEX = /(?:https?:\/\/)?(?:www\.)?rutube\.ru\/play\/embed\/([a-zA-Z0-9_-]{32})/i;

export default class RuTube extends React.Component<Props> {
    static ENABLED = [BASE_REGEX, LIVE_REGEX, PRIVATE_REGEX, EMBED_REGEX];

    render() {
        const { matches } = this.props.attrs;
        const videoId = matches[1] === "private" ? `${matches[2]}${matches[3]}` : matches[1];
        return (
            <Frame
                {...this.props}
                src={`https://rutube.ru/play/embed/${videoId}`}
                title={`RuTube (${videoId})`}
            />
        );
    }
}
