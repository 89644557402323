import * as React from "react";
import Frame from "../components/Frame";
import { EmbedProps as Props } from ".";

export default class YandexMaps extends React.Component<Props> {
  static ENABLED = [
    new RegExp("https:\/\/yandex\.ru\/maps\/-\/([a-zA-Z\-0-9]+)"),
  ];

  render() {
    const { matches } = this.props.attrs;
    const shareId = matches[1];
    return (
      <Frame
        {...this.props}
        src={`https://yandex.ru/map-widget/v1/-/${shareId}`}
        title="Yandex Maps"
      />
    );
  }
}
