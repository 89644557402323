import { makeObservable } from "mobx";
import BaseModel from "~/models/BaseModel";

class Attachment extends BaseModel {
  constructor(fields: any, store: any) {
    super(fields, store);
    Object.assign(this, fields);
    makeObservable(this);
  }

  contentType: string;

  name: string;

  size: string;

  key: string;

  url: string;

  user: {
    name: string;
    id: string;
  }
}

export default Attachment;
