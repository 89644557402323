import * as React from "react";
import Frame from "../components/Frame";
import { EmbedProps as Props } from ".";

export default class YandexCalendar extends React.Component<Props> {
  static ENABLED = [
    new RegExp("https:\/\/calendar\.yandex\.ru\/embed\/(week|month|day)\\?private_token=[a-z0-9]{40}"),
  ];

  render() {
    return (
      <Frame
        {...this.props}
        src={this.props.attrs.href}
        title="Yandex Calendar"
      />
    );
  }
}
