// хук необходим для корректного расчета vh для мобильных устройств
// так как css не учитывает Safe area для мобилок

export function useVh(value?: number) {
  const vh = window.innerHeight * 0.01;

  if (!value) {
    return vh;
  }

  return value * vh;
}