import React, { ComponentProps } from "react";
import Icon from "./Icon/Icon";

type IconProps = ComponentProps<typeof Icon>;

export function IcBaselineFormatAlignLeft(props: IconProps) {
  return (
    <Icon {...props}>
      <path d="M15 15H3v2h12v-2zm0-8H3v2h12V7zM3 13h18v-2H3v2zm0 8h18v-2H3v2zM3 3v2h18V3H3z"></path>
    </Icon>
  );
}

export default IcBaselineFormatAlignLeft;
