import get from "lodash/get";
import { action, makeObservable, observable } from "mobx";
import { PaymentMethod } from "@shared/types";
import RootStore from "~/stores/RootStore";
import { TPaymentHistory } from "~/scenes/Settings/Tariff/components/TransactionHistory";
import env from "~/env";
import { client } from "~/utils/ApiClient";
import type { NextPaymentInfo, Order, Quota, QuotaActionType, QuotaIdentType, Tariff, TariffAbilities, TeamMetricsSummary } from "../../quota/types";

export default class TariffStore {
  @observable
  primaryTariff: Tariff;

  @observable
  defaultTariff: Tariff;

  @observable
  tariffs: Tariff[];

  @observable
  teamMetrics: TeamMetricsSummary;

  @observable
  quotas: Quota[];

  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeObservable(this);
  }

  @action.bound
  getNextPaymentInfo = async (): Promise<NextPaymentInfo | null> => {
    try {
      const tariffInfo = await client.post("/tariff.team.nextPaymentInfo")
      return tariffInfo.data
    } catch {
      return null
    }
  }

  @action.bound
  getDefaultTariff = async () => {
    try {
      const resp = await client.post("/tariff.default");
      this.defaultTariff = resp.data.tariff;
    } catch {
      this.defaultTariff = this.primaryTariff;
    }
  }

  @action.bound
  getPaymentHistory = async (): Promise<TPaymentHistory[]> => {
    try {
      const resp = await client.post("/tariff.team.paymentHistory")
      return resp.data;
    } catch (err) {
      throw new Error(err.message);
    }
  }

  @action.bound
  addPaymentMethod = async () => {
    const result = await client.post(
      "/tariff.team.paymentMethods.add",
      {
        redirectAfterPayment: window.location.href,
      }
    );
    return result.data;
  }

  private getAbility(ident: QuotaIdentType, action: QuotaActionType): TariffAbilities {
    if (!this.teamMetrics.actions[ident]) {
      return {
        available: true
      };
    }
    if (this.teamMetrics.actions[ident].includes(action)) {
      return get(this.teamMetrics.metrics, ident, {
        available: 0
      });
    }
    return { available: true }
  }

  /*
  * некоторые метрики могут иметь один и тот же action
  * если вас интересует значение конкретного идента, стоит передать abilityIdent
  **/
  abilities(action: QuotaActionType, abilityIdent?: QuotaIdentType): TariffAbilities {
    if (!this.teamMetrics.actions) {
      return {
        available: true
      };
    }

    if (abilityIdent) {
      return this.getAbility(abilityIdent, action);
    } else {
      for (const ident of Object.keys(this.teamMetrics.actions)) {
        const ability = this.getAbility(ident as QuotaIdentType, action);
        if (ability) {
          return ability;
        }
      }
    }

    return {
      available: true
    };
  }

  @action.bound
  canUpload(size: number): boolean {
    const abilities = this.abilities('attachments.create');
    if (abilities.available === true) {
      return true
    }
    return size <= abilities.available;
  }

  @action.bound
  tryUpload(file: File) {
    if (!this.quotasEnabled()) {
      return;
    }
    if (!this.canUpload(file.size)) {
      throw new Error('You ran out of space allocated by your tariff');
    }
  }

  async createOrder(tariffId: string, discountId?: string): Promise<Order> {
    const result = await client.post('/tariff.team.createOrder', {
      tariffId,
      discountId,
    });
    return result.data;
  }

  async createTransaction(orderId: number, paymentGateway?: string): Promise<{
    trxId: string,
    orderId: number,
    redirectUrl: string,
  }> {
    const result = await client.post(
      `/tariff.team.createTransaction`,
      {
        orderId,
        paymentGateway,
        redirectAfterPayment: window.location.href,
        // redirectAfterPayment: 'https://yonote.ru?test1=123',
      }
    );
    return result.data;
  }

  async createSubscription(tariffId: string): Promise<{
    orderId: number
    redirectUrl: string
    trxId: string
  }> {
    const result = await client.post(
      `/tariff.team.purchaseTariff`,
      {
        tariffId,
        redirectAfterPayment: window.location.href,
      }
    );
    return result.data;
  }

  async getPaymentMethods(): Promise<PaymentMethod[]> {
    const result = await client.post(`/tariff.team.paymentMethods`);
    return result.data;
  }

  async deletePaymentMethod(paymentMethodId: string): Promise<any> {
    const result = await client.post(`/tariff.team.delete.paymentMethod`, { paymentMethodId });
    return result.data;
  }

  @action.bound
  async fetchTariffs() {
    try {
      const result = await client.post('/tariff.info');
      this.tariffs = result?.data?.map((item: { teamId: string, tariff: Tariff }) => item.tariff as Tariff);
      this.primaryTariff = this.tariffs[0] || null; // FIXME detect primary tariff later
      this.quotas = [];
      this.tariffs.map(tariff => {
        tariff.quotas.forEach(quota => this.quotas.push(quota))
      });
    } catch (e) {
      this.quotas = [];
      this.tariffs = [];
    }
  }

  @action.bound
  setTeamMetrics(metricsSummary: TeamMetricsSummary) {
    this.teamMetrics = metricsSummary;
  }

  @action.bound
  async fetchTeamMetrics() {
    try {
      const result = await client.post('/tariff.team.metrics');
      this.setTeamMetrics(result.data);
    } catch (e) {
      this.teamMetrics = {
        metrics: {},
        actions: {},
      }
    }
  }

  async fetchAvailableTariffs(): Promise<Tariff[]> {
    try {
      const result = await client.post('/tariff.team.available.tariffs');
      return result.data;
    } catch (e) {
      return [];
    }
  }

  async getSubscriptionPrice(tariffId: string, discountId?: string): Promise<{
    total: number
    tariffCost: number
    usersCount: number
    tariffPrice?: number
    costDifference?: number
  } | false> {
    try {
      const result = await client.post('/tariff.subscriptionPrice', { tariffId, discountId });
      return result.data;
    } catch (e) {
      return false;
    }
  }

  @action
  clear() {
    // clear something
  }

  quotasEnabled() {
    return env?.QUOTA_ENABLED
  }
}
