export const USER_PRESENCE_INTERVAL = 5000;

export const MAX_AVATAR_DISPLAY = 6;

export const MAX_TITLE_LENGTH = 1024;

export const MAX_COMMENT_LENGTH = 5000;

export const IMPORT_LEEWAY_SPACE = 5242880; // 5mb

/**
  * if the user has no tariff restrictions for imports
  * MAX_ALLOWED_IMPORT_FILE_SIZE will become the default.
  * In case any restricions should be present due to technical
  * surcomstances, you can always set it here in bytes
  * */
export const MAX_ALLOWED_IMPORT_FILE_SIZE = Infinity;

export const YONOTE_PRICING_LINK = "https://yonote.ru/pricing";

export const THROTTLING_SEND_EMAIL_DOCUMENT_DIFF_INTERVAL = 30 // Отправлять EMAIL диффы обновленного документа не чаще ... (минуты)

export const THROTTLING_SEND_PUSHES_DOCUMENT_DIFF_INTERVAL = 10 // Отправлять PUSH диффы обновленного документа не чаще ... (минуты)

export const YONOTE_WEBSITE = "https://yonote.ru/";

export const DEFAULT_PAGE_LIMIT = 100;

export const TO_EDITOR_EVENT = "to_editor_event";

export const TO_COMPONENT_EVENT = "to_component_event";

export const ATTACHMENTS_REGEXP = /\[([a-zA-Z0-9:\/\?\.=\- ]*)\](\(\/api\/attachments\.redirect.*?\)|\(attachment:\/api\/attachments\.redirect.*?\))/g;

export const ATTACHMENTS_SHARE_REGEXP = /\[([a-zA-Z0-9:\/\?\.=\- ]*)\]\((.*?)\)/g;

export const MENTION_REGEXP = /\[(.*?)\]\(mention:\/\/\?id=(.*?)&type=(.*?)&targetId=(.*?)&actorId=(.*?)\)/gm;
