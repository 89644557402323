import React, { ComponentProps } from "react";
import Icon from "./Icon/Icon";

type IconProps = ComponentProps<typeof Icon>;

export function IcOutlineArrowDropDown(props: IconProps) {
  return (
    <Icon {...props}>
      <path d="m7 10l5 5l5-5H7z"></path>
    </Icon>
  );
}

export default IcOutlineArrowDropDown;
