import React from "react";
import Frame, { defaultSandboxSettings } from "../components/Frame";
import { EmbedProps as Props } from ".";

const sandboxSettings = `${defaultSandboxSettings} allow-popups-to-escape-sandbox`;

function GoogleMaps(props: Props) {
  const { matches } = props.attrs;
  const source = matches[0];

  return (
    <Frame
      {...props}
      src={source}
      frameSandbox={sandboxSettings}
      title="Google Maps"
    />
  );
}

GoogleMaps.ENABLED = [
  new RegExp("^https?://www\.google\.com/maps/embed\?(.*)$")
];

export default GoogleMaps;
