import React, { ComponentProps } from "react";
import styled from "styled-components";
import Icon from "./Icon/Icon";

const StyledIcon = styled(Icon)`
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
`;

type IconProps = ComponentProps<typeof StyledIcon>;

const IcBaselineFormatColorText = (props: IconProps) => {
  return (
    <Icon {...props}>
        <path d="M2 20h20v4H2v-4zm3.49-3h2.42l1.27-3.58h5.65L16.09 17h2.42L13.25 3h-2.5L5.49 17zm4.42-5.61l2.03-5.79h.12l2.03 5.79H9.91z"/>
    </Icon>
  );
};

export default IcBaselineFormatColorText;
