import { makeObservable, observable } from "mobx";
import BaseModel from "./BaseModel";
import Field from "./decorators/Field";

class Pin extends BaseModel {
  constructor(fields: any, store: any) {
    super(fields, store);
    Object.assign(this, fields);
    makeObservable(this);
  }

  id: string;
  collectionId: string;
  documentId: string;

  @observable
  @Field
  index: string;
}

export default Pin;
