import { action, makeObservable, observable } from "mobx";

export type Heading = {
  title: string;
  level: number;
  id: string;
}

export default class ContentsStore {
  @observable
  public headings: Heading[] = [];

  constructor() {
    makeObservable(this);
  }

  @action.bound
  setHeadings(headings: Heading[]) {
    this.headings = headings;
  }

  @action.bound
  clear() {
    this.headings = [];
  }
}
