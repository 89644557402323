import { sortBy, filter } from "lodash";
import { makeObservable, override } from "mobx";
import Event from "~/models/Event";
import BaseStore, { RPCAction } from "./BaseStore";
import RootStore from "./RootStore";

export default class EventsStore extends BaseStore<Event> {
  actions = [RPCAction.List];

  constructor(rootStore: RootStore) {
    super(rootStore, Event);
    makeObservable(this);
  }

  @override
  get orderedData(): Event[] {
    return sortBy(Array.from(this.data.values()), "createdAt").reverse();
  }

  #filterRevisionsPerQuota(events: Event[], quota: number): Event[] {
    return events.filter(event => {
      if (event.name !== "revisions.create") {
        return true;
      }

      if (quota === 0) {
        return false;
      }

      quota--;
      return true;
    });
  }

  inDocument(documentId: string, quota?: number): Event[] {
    const events = filter(this.orderedData, (event) => event.documentId === documentId);
    if (!quota) {
      return events;
    }
    return this.#filterRevisionsPerQuota(events, quota);
  }
}
