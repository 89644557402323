import React from "react";
import embeds, { Img, matcher } from "@shared/editor/embeds";
import OtherService from "@shared/editor/embeds/OtherService";

export const DRAWER_WIDTH = 400;

export const DRAWER_OFFSET_TOP = 1;

export const DRAWER_CHANGE_LINK_OFFSET_TOP = 54;


export const embedsWithOtherService = [
  ...embeds,
  {
    title: "Other service",
    keywords: "other service другой сервис",
    icon: () => <Img src="/images/globe.png" alt="Other service"></Img>,
    component: OtherService,
    matcher: matcher(OtherService),
    link: "https://example.com/...",
    type: "otherService",
  },
];
