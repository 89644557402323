import { makeObservable, observable, override } from "mobx";
import BaseModel from "./BaseModel";
import Field from "./decorators/Field";

class WebhookSubscription extends BaseModel {
  constructor(fields: any, store: any) {
    super(fields, store);
    Object.assign(this, fields);
    makeObservable(this)
  }

  @Field
  @override
  id: string;

  @Field
  @observable
  name: string;

  @Field
  @observable
  url: string;

  @Field
  @observable
  enabled: boolean;

  @Field
  @observable
  events: string[];
}

export default WebhookSubscription;
