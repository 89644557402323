import React, { ComponentProps } from "react";
import Icon from "./Icon/Icon";

type IconProps = ComponentProps<typeof Icon>;

export function TablerSpeakerphone(props: IconProps) {
  return (
    <Icon {...props}>
      <g strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
        <path d="M18 8a3 3 0 0 1 0 6m-8-6v11a1 1 0 0 1-1 1H8a1 1 0 0 1-1-1v-5"></path>
        <path d="M12 8h0l4.524-3.77A.9.9 0 0 1 18 4.922v12.156a.9.9 0 0 1-1.476.692L12 14H4a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1h8"></path>
      </g>
    </Icon>
  );
}

export default TablerSpeakerphone;
