import "focus-visible";
import { LazyMotion } from "framer-motion";
import { KBarProvider } from "kbar";
import { Provider } from "mobx-react";
import * as React from "react";
import { render } from "react-dom";
import { Router } from "react-router-dom";
import { initI18n } from "@shared/i18n";
import stores from "~/stores";
import Analytics from "~/components/Analytics";
import Dialogs from "~/components/Dialogs";
import ErrorBoundary from "~/components/ErrorBoundary";
import FileDownloadController from "~/components/FileDownloadController";
import PageTheme from "~/components/PageTheme";
import ScrollToTop from "~/components/ScrollToTop";
import Theme from "~/components/Theme";
import Toasts from "~/components/Toasts";
import env from "~/env";
import LazyPolyfill from "./components/LazyPolyfills";
import Routes from "./routes";
import history from "./utils/history";
import { initSentry } from "./utils/sentry";

export const i18n = initI18n();
const element = window.document.getElementById("root");


async function regWorker () {
  fetch(`/api/users.timezone`, {
    method: "POST",
    body: JSON.stringify({
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
    }),
    headers: { "content-type": "application/json" }
  })
  // REGISTER SERVICE WORKER
  await navigator.serviceWorker.register("/static/service-worker.js", { scope: "/" });

  // SUBSCRIBE TO PUSH SERVER
  navigator.serviceWorker.ready
    .then(reg => {
      reg.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: env.SERVICE_WORKER_PUBLIC_KEY
      }).then(
        sub => {
          console.log(sub)
          fetch(`/api/notificationSettings.push_subscribe`, {
            method: "POST",
            body: JSON.stringify(sub),
            headers: { "content-type": "application/json" }
          })
        },
        // ERROR!
        err => console.error(err)
      );
    });
}

if (env.SENTRY_DSN) {
  initSentry(history);
}

if ("serviceWorker" in window.navigator && env.SERVICE_WORKER_PUBLIC_KEY && ("Notification" in window)) {
  window.onload = () => {
    // PERMISSION
    if (Notification.permission === "default") {
      Notification.requestPermission().then(() => {
        if (Notification.permission === "granted") {
          regWorker().catch(err => console.error(err));
        }
      });
    }

    // GRANTED
    else if (Notification.permission === "granted") {
      regWorker().catch(err => console.error(err));
    }
  }

  // REGISTER SERVICE WORKER
}

// Make sure to return the specific export containing the feature bundle.
const loadFeatures = () => import("./utils/motion").then((res) => res.default);

const commandBarOptions = {
  animations: {
    enterMs: 250,
    exitMs: 200,
  },
  callbacks: {
    onClose: () => stores.ui.commandBarClosed(),
  },
};

if (element) {
  const App = () => (
    <React.StrictMode>
      <Provider {...stores}>
        <Analytics />
        <Theme>
          <ErrorBoundary>
            <KBarProvider actions={[]} options={commandBarOptions}>
              <LazyPolyfill>
                <LazyMotion features={loadFeatures}>
                  <Router history={history}>
                    <>
                      <PageTheme />
                      <ScrollToTop>
                        <Routes />
                      </ScrollToTop>
                      <Toasts />
                      <FileDownloadController />
                      <Dialogs />
                    </>
                  </Router>
                </LazyMotion>
              </LazyPolyfill>
            </KBarProvider>
          </ErrorBoundary>
        </Theme>
      </Provider>
    </React.StrictMode>
  );

  render(<App />, element);
}

window.addEventListener("load", async () => {
  // installation does not use Google Analytics, or tracking is blocked on client
  // no point loading the rest of the analytics bundles
  if (!env.GOOGLE_ANALYTICS_ID || !window.ga) {
    return;
  }
  // https://github.com/googleanalytics/autotrack/issues/137#issuecomment-305890099
  await import(
    /* webpackChunkName: "autotrack" */
    "autotrack/autotrack.js"
  );
  window.ga("require", "outboundLinkTracker");
  window.ga("require", "urlChangeTracker");
  window.ga("require", "eventTracker", {
    attributePrefix: "data-",
  });
});
