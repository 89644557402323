import { motion } from "framer-motion";
import { transparentize } from "polished";
import React, {
  ReactElement,
  PropsWithChildren,
  CSSProperties,
  useCallback
} from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import breakpoint from "styled-components-breakpoint";
import depths from "@shared/styles/depths";
import { BackIcon, CloseIcon } from "~/components/Icons";
import NudeButton from "~/components/NudeButton";
import Text from "~/components/Text";
import useBoolean from "~/hooks/useBoolean";
import { useVh } from "~/hooks/useVh";
import { StyledWithVh } from "~/types";
import { MOTION_PARAMS, BASE_CONTENT_WIDTH } from "@uikit/Modal/constants";

type Props = {
  onRequestClose: () => void;
  title: ReactElement | string;
  style: CSSProperties;
  showCloseButton: boolean;
  className?: string;
}

const FullscreenModal = ({
  title,
  onRequestClose,
  style,
  showCloseButton,
  className,
  children,
}: PropsWithChildren<Props>) => {
  const { t } = useTranslation();
  const vh = useVh();
  const [showContent, setShowContent] = useBoolean(false);

  // Таким способом предотвращаем неприятное подергивание кнопки в заголовке при завершении
  // анимации появления модального окна
  const onAnimationComplete = useCallback(() => setImmediate(setShowContent), [setShowContent]);

  return (
    <FullscreenModalWrapper
      {...MOTION_PARAMS}
      onAnimationComplete={onAnimationComplete}
      style={style}
      className={className}
      vh={vh}
    >
      {showCloseButton && (
        <Header>
          <Close onClick={onRequestClose}>
            <CloseIcon size={32} color="currentColor" />
          </Close>
          <Back onClick={onRequestClose}>
            <BackIcon size={32} color="currentColor" />
            <Text as="span">{t("Back")} </Text>
          </Back>
        </Header>
      )}
      {showContent && (
        <Content vh={vh}>
          {typeof title === "string" ? <h1>{title}</h1> : title}
          {children}
        </Content>
      )}
    </FullscreenModalWrapper>
  );
}

const FullscreenModalWrapper = motion(styled.div<StyledWithVh>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: ${({ vh }) => `${100 * vh}px`};
  box-sizing: border-box;
  overflow: auto;
  
  display: flex;
  justify-content: center;
  
  background-color: ${({ theme }) => theme.modalBackground};
  
  padding: 24px;

  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.scrollbarThumb};
  }
`);

const Header = styled.div`
  position: fixed;
  
  top: 0;
  left: 0;
  width: calc(100% - 10px);
  height: 50px;
  box-sizing: border-box;
  
  backdrop-filter: blur(20px);
  background: ${({ theme }) => transparentize(0.5, theme.background)};
  
  z-index: ${depths.modal};
  
  ${breakpoint("tablet")`
    height: 80px;
  `};
`;

const Content = styled.div<StyledWithVh>`
  max-width: ${BASE_CONTENT_WIDTH}px;
  padding-top: ${({ vh }) => `${10 * vh}px`};
`;

const Close = styled(NudeButton)`
  position: fixed;
  display: block;
  top: 0;
  right: 0;
  margin: 12px;
  opacity: 0.75;
  color: ${({ theme }) => theme.text};
  width: auto;
  height: auto;

  &:hover {
    opacity: 1;
  }

  ${breakpoint("tablet")`
    display: none;
  `};
`;

const Back = styled(NudeButton)`
  position: fixed;
  display: none;
  align-items: center;
  top: 32px;
  left: 32px;
  opacity: 0.75;
  color: ${({ theme }) => theme.text};
  font-weight: 500;
  width: auto;
  height: auto;

  &:hover {
    opacity: 1;
  }

  ${breakpoint("tablet")`
    display: flex;
  `};
`;

export default FullscreenModal;
