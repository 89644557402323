import * as React from "react";
import styled from "styled-components";
import { EmbedDescriptor } from "@shared/editor/types";
import Image from "../components/Image";
import Abstract from "./Abstract";
import Airtable from "./Airtable";
import Berrycast from "./Berrycast";
import Bilibili from "./Bilibili";
import Cawemo from "./Cawemo";
import ClickUp from "./ClickUp";
import Codepen from "./Codepen";
import DBDiagram from "./DBDiagram";
import Descript from "./Descript";
import Diagrams from "./Diagrams";
import Figma from "./Figma";
import Framer from "./Framer";
import Gist from "./Gist";
import Gliffy from "./Gliffy";
import GoogleCalendar from "./GoogleCalendar";
import GoogleDataStudio from "./GoogleDataStudio";
import GoogleDocs from "./GoogleDocs";
import GoogleDrawings from "./GoogleDrawings";
import GoogleDrive from "./GoogleDrive";
import GoogleMaps from "./GoogleMaps";
import GoogleSheets from "./GoogleSheets";
import GoogleSlides from "./GoogleSlides";
import InVision from "./InVision";
import JSFiddle from "./JSFiddle";
import Kinescope from "./Kinescope";
import Loom from "./Loom";
import Lucidchart from "./Lucidchart";
import Marvel from "./Marvel";
import Mindmeister from "./Mindmeister";
import Miro from "./Miro";
import ModeAnalytics from "./ModeAnalytics";
import Otter from "./Otter";
import Pitch from "./Pitch";
import Prezi from "./Prezi";
import RuTube from "./RuTube";
import Spotify from "./Spotify";
import Tldraw from "./Tldraw";
import Trello from "./Trello";
import Typeform from "./Typeform";
import Vimeo from "./Vimeo";
import Whimsical from "./Whimsical";
import YandexCalendar from "./YandexCalendar";
import YandexMaps from "./YandexMaps";
import YandexMusic from "./YandexMusic";
import YouTube from "./YouTube";

export type EmbedProps = {
  isSelected: boolean;
  isEditable: boolean;
  embed: EmbedDescriptor;
  attrs: {
    href: string;
    matches: RegExpMatchArray;
  };
};

export function matcher(Component: React.ComponentType<EmbedProps>) {
  return (url: string): boolean | [] | RegExpMatchArray => {
    // @ts-expect-error not aware of static
    const regexes = Component.ENABLED;

    for (const regex of regexes) {
      const result = url.match(regex);

      if (result) {
        return result;
      }
    }

    return false;
  };
}

export const Img = styled(Image)`
  border-radius: 2px;
  background: #fff;
  box-shadow: 0 0 0 1px #fff;
  margin: 4px;
  width: 18px;
  height: 18px;
`;

const embeds: EmbedDescriptor[] = [
  {
    title: "Abstract",
    keywords: "design",
    defaultHidden: true,
    icon: () => <Img src="/images/abstract.png" alt="Abstract" />,
    component: Abstract,
    matcher: matcher(Abstract),
    link: "https://www.abstract.com/...",
    type: "abstract",
  },
  {
    title: "Airtable",
    keywords: "spreadsheet",
    icon: () => <Img src="/images/airtable.png" alt="Airtable" />,
    component: Airtable,
    matcher: matcher(Airtable),
    link: "https://www.airtable.com/...",
    type: "airtable",
  },
  {
    title: "Berrycast",
    keywords: "video",
    defaultHidden: true,
    icon: () => <Img src="/images/berrycast.png" alt="Berrycast" />,
    component: Berrycast,
    matcher: matcher(Berrycast),
    link: "https://www.berrycast.com/...",
    type: "berrycast",
  },
  {
    title: "Bilibili",
    keywords: "video",
    defaultHidden: true,
    icon: () => <Img src="/images/bilibili.png" alt="Bilibili" />,
    component: Bilibili,
    matcher: matcher(Bilibili),
    link: "https://www.bilibili.com/...",
    type: "bilibili",
  },
  {
    title: "Cawemo",
    keywords: "bpmn process",
    defaultHidden: true,
    icon: () => <Img src="/images/cawemo.png" alt="Cawemo" />,
    component: Cawemo,
    matcher: matcher(Cawemo),
    link: "https://cawemo.com/...",
    type: "cawemo",
  },
  {
    title: "ClickUp",
    keywords: "project",
    icon: () => <Img src="/images/clickup.png" alt="ClickUp" />,
    component: ClickUp,
    matcher: matcher(ClickUp),
    link: "https://clickup.com/...",
    type: "clickUp",
  },
  {
    title: "Codepen",
    keywords: "code editor",
    icon: () => <Img src="/images/codepen.png" alt="Codepen" />,
    component: Codepen,
    matcher: matcher(Codepen),
    link: "https://codepen.io/...",
    type: "codepen",
  },
  {
    title: "DBDiagram",
    keywords: "diagrams database",
    icon: () => <Img src="/images/dbdiagram.png" alt="DBDiagram" />,
    component: DBDiagram,
    matcher: matcher(DBDiagram),
    link: "https://dbdiagram.io/...",
    type: "dbDiagram",
  },
  {
    title: "Descript",
    keywords: "audio",
    icon: () => <Img src="/images/descript.png" alt="Descript" />,
    component: Descript,
    matcher: matcher(Descript),
    link: "https://www.descript.com/...",
    type: "descript",
  },
  {
    title: "Figma",
    keywords: "design svg vector",
    icon: () => <Img src="/images/figma.png" alt="Figma" />,
    component: Figma,
    matcher: matcher(Figma),
    link: "https://www.figma.com/...",
    type: "figma",
  },
  {
    title: "Framer",
    keywords: "design prototyping",
    icon: () => <Img src="/images/framer.png" alt="Framer" />,
    component: Framer,
    matcher: matcher(Framer),
    link: "https://www.framer.com/...",
    type: "framer",
  },
  {
    title: "GitHub Gist",
    keywords: "code",
    icon: () => <Img src="/images/github-gist.png" alt="GitHub" />,
    component: Gist,
    matcher: matcher(Gist),
    link: "https://gist.github.com/...",
    type: "gitHubGist",
  },
  {
    title: "Gliffy",
    keywords: "diagram",
    icon: () => <Img src="/images/gliffy.png" alt="Gliffy" />,
    component: Gliffy,
    matcher: matcher(Gliffy),
    link: "https://www.gliffy.com/...",
    type: "gliffy",
  },
  {
    title: "Diagrams.net",
    keywords: "diagrams drawio",
    icon: () => <Img src="/images/diagrams.png" alt="Diagrams.net" />,
    component: Diagrams,
    matcher: matcher(Diagrams),
    link: "https://diagrams.net/...",
    type: "diagramsNet",
  },
  {
    title: "Google Maps",
    keywords: "google maps гугл карты",
    icon: () => <Img src="/images/google-maps.png" alt="Google maps" />,
    component: GoogleMaps,
    matcher: matcher(GoogleMaps),
    link: "https://google.com/maps...",
    type: "googleMaps",
  },
  {
    title: "Google Drawings",
    keywords: "drawings",
    icon: () => <Img src="/images/google-drawings.png" alt="Google Drawings" />,
    component: GoogleDrawings,
    matcher: matcher(GoogleDrawings),
    link: "https://docs.google.com/drawings/...",
    type: "googleDrawings",
  },
  {
    title: "Google Drive",
    keywords: "drive",
    icon: () => <Img src="/images/google-drive.png" alt="Google Drive" />,
    component: GoogleDrive,
    matcher: matcher(GoogleDrive),
    link: "https://drive.google.com/...",
    type: "googleDrive",
  },
  {
    title: "Google Docs",
    keywords: "documents word",
    icon: () => <Img src="/images/google-docs.png" alt="Google Docs" />,
    component: GoogleDocs,
    matcher: matcher(GoogleDocs),
    link: "https://docs.google.com/...",
    type: "googleDocs",
  },
  {
    title: "Google Sheets",
    keywords: "excel spreadsheet",
    icon: () => <Img src="/images/google-sheets.png" alt="Google Sheets" />,
    component: GoogleSheets,
    matcher: matcher(GoogleSheets),
    link: "https://docs.google.com/spreadsheets/...",
    type: "googleSheets",
  },
  {
    title: "Google Slides",
    keywords: "presentation slideshow",
    icon: () => <Img src="/images/google-slides.png" alt="Google Slides" />,
    component: GoogleSlides,
    matcher: matcher(GoogleSlides),
    link: "https://www.google.com/slides/...",
    type: "googleSlides",
  },
  {
    title: "Google Calendar",
    keywords: "calendar",
    icon: () => <Img src="/images/google-calendar.png" alt="Google Calendar" />,
    component: GoogleCalendar,
    matcher: matcher(GoogleCalendar),
    link: "https://calendar.google.com/...",
    type: "googleCalendar",
  },
  {
    title: "Google Data Studio",
    keywords: "bi business intelligence",
    icon: () => (
      <Img src="/images/google-datastudio.png" alt="Google Data Studio" />
    ),
    component: GoogleDataStudio,
    matcher: matcher(GoogleDataStudio),
    link: "https://datastudio.withgoogle.com/...",
    type: "googleDataStudio",
  },
  {
    title: "InVision",
    keywords: "design prototype",
    defaultHidden: true,
    icon: () => <Img src="/images/invision.png" alt="InVision" />,
    component: InVision,
    matcher: matcher(InVision),
    link: "https://www.invisionapp.com/...",
    type: "inVision",
  },
  {
    title: "JSFiddle",
    keywords: "code",
    defaultHidden: true,
    icon: () => <Img src="/images/jsfiddle.png" alt="JSFiddle" />,
    component: JSFiddle,
    matcher: matcher(JSFiddle),
    link: "https://jsfiddle.net/...",
    type: "jsFiddle",
  },
  {
    title: "Kinescope",
    keywords: "video kinescope",
    defaultHidden: true,
    icon: () => <Img src="/images/kinescope.png" alt="kinescope" />,
    component: Kinescope,
    matcher: matcher(Kinescope),
    link: "https://kinescope.io/...",
    type: "kinescope",
  },
  {
    title: "Loom",
    keywords: "video screencast",
    icon: () => <Img src="/images/loom.png" alt="Loom" />,
    component: Loom,
    matcher: matcher(Loom),
    link: "https://www.loom.com/...",
    type: "loom",
  },
  {
    title: "Lucidchart",
    keywords: "chart",
    icon: () => <Img src="/images/lucidchart.png" alt="Lucidchart" />,
    component: Lucidchart,
    matcher: matcher(Lucidchart),
    link: "https://www.lucidchart.com/pages/...",
    type: "lucidchart",
  },
  {
    title: "Marvel",
    keywords: "design prototype",
    icon: () => <Img src="/images/marvel.png" alt="Marvel" />,
    component: Marvel,
    matcher: matcher(Marvel),
    link: "https://marvelapp.com/...",
    type: "marvel",
  },
  {
    title: "Mindmeister",
    keywords: "mindmap",
    icon: () => <Img src="/images/mindmeister.png" alt="Mindmeister" />,
    component: Mindmeister,
    matcher: matcher(Mindmeister),
    link: "https://mindmeister.com/...",
    type: "mindmeister",
  },
  {
    title: "Miro",
    keywords: "whiteboard",
    icon: () => <Img src="/images/miro.png" alt="Miro" />,
    component: Miro,
    matcher: matcher(Miro),
    link: "https://miro.com/...",
    type: "miro",
  },
  {
    title: "Mode",
    keywords: "analytics",
    defaultHidden: true,
    icon: () => <Img src="/images/mode-analytics.png" alt="Mode" />,
    component: ModeAnalytics,
    matcher: matcher(ModeAnalytics),
    link: "https://modeanalytics.com/...",
    type: "mode",
  },
  {
    title: "Otter.ai",
    keywords: "audio transcription meeting notes",
    defaultHidden: true,
    icon: () => <Img src="/images/otter.png" alt="Otter.ai" />,
    component: Otter,
    matcher: matcher(Otter),
    link: "https://otter.ai/...",
    type: "otter.ai",
  },
  {
    title: "Pitch",
    keywords: "presentation",
    defaultHidden: true,
    icon: () => <Img src="/images/pitch.png" alt="Pitch" />,
    component: Pitch,
    matcher: matcher(Pitch),
    link: "https://pitch.com/...",
    type: "pitch",
  },
  {
    title: "Prezi",
    keywords: "presentation",
    icon: () => <Img src="/images/prezi.png" alt="Prezi" />,
    component: Prezi,
    matcher: matcher(Prezi),
    link: "https://prezi.com/...",
    type: "prezi",
  },
  {
    title: "Spotify",
    keywords: "music",
    icon: () => <Img src="/images/spotify.png" alt="Spotify" />,
    component: Spotify,
    matcher: matcher(Spotify),
    link: "https://open.spotify.com/...",
    type: "spotify",
  },
  {
    title: "Tldraw",
    keywords: "draw schematics diagrams",
    icon: () => <Img src="/images/tldraw.png" alt="Tldraw" />,
    component: Tldraw,
    matcher: matcher(Tldraw),
    link: "https://tldraw.com/...",
    type: "tldraw",
  },
  {
    title: "Trello",
    keywords: "kanban",
    icon: () => <Img src="/images/trello.png" alt="Trello" />,
    component: Trello,
    matcher: matcher(Trello),
    link: "https://trello.com/...",
    type: "trello",
  },
  {
    title: "Typeform",
    keywords: "form survey",
    icon: () => <Img src="/images/typeform.png" alt="Typeform" />,
    component: Typeform,
    matcher: matcher(Typeform),
    link: "https://www.typeform.com/...",
    type: "typeform",
  },
  {
    title: "Vimeo",
    keywords: "video",
    icon: () => <Img src="/images/vimeo.png" alt="Vimeo" />,
    component: Vimeo,
    matcher: matcher(Vimeo),
    link: "https://vimeo.com/...",
    type: "vimeo",
  },
  {
    title: "Whimsical",
    keywords: "whiteboard",
    icon: () => <Img src="/images/whimsical.png" alt="Whimsical" />,
    component: Whimsical,
    matcher: matcher(Whimsical),
    link: "https://whimsical.com/...",
    type: "whimsical",
  },
  {
    title: "YouTube",
    keywords: "google video",
    icon: () => <Img src="/images/youtube.png" alt="YouTube" />,
    component: YouTube,
    matcher: matcher(YouTube),
    link: "https://www.youtube.com/...",
    type: "youTube",
  },
  {
    title: "RuTube",
    keywords: "rutube",
    icon: () => <Img src="/images/rutube.png" alt="RuTube" />,
    component: RuTube,
    matcher: matcher(RuTube),
    link: "https://rutube.ru/...",
    type: "ruTube",
  },
  {
    title: "Yandex Maps",
    keywords: "yandex maps яндекс карты",
    icon: () => <Img src="/images/yandex-maps.png" alt="Yandex Maps" />,
    component: YandexMaps,
    matcher: matcher(YandexMaps),
    link: "https://yandex.ru/maps...",
    type: "yandexMaps",
  },
  {
    title: "Yandex Music",
    keywords: "yandex music яндекс музыка",
    icon: () => <Img src="/images/yandex-music.png" alt="Yandex music" />,
    component: YandexMusic,
    matcher: matcher(YandexMusic),
    link: "https://music.yandex.ru/...",
    type: "yandexMusic",
  },
  {
    title: "Yandex Calendar",
    keywords: "yandex calendar яндекс календарь",
    icon: () => <Img src="/images/yandex-calendar.png" alt="Yandex music" />,
    component: YandexCalendar,
    matcher: matcher(YandexCalendar),
    link: "https://calendar.yandex.ru/...",
    type: "yandexCalendar",
  },
];

export default embeds;
