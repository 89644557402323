export type Role = "admin" | "viewer" | "member";

export type DateFilter = "day" | "week" | "month" | "year";

export enum NotificationType {
  Email = "email",
  Push = "push",
}

export enum NotificationEvent {
  DocumentsPublish = "documents.publish",
  DocumentsUpdate = "documents.update",
  CollectionsCreate = "collections.create",
  EmailsInviteAccepted = "emails.invite_accepted",
  EmailsOnboarding = "emails.onboarding",
  EmailsFeatures = "emails.features",
  CalendarEvents = "calendar.events",
  CommentEvents = "comment.events",
  MentionEvents = "mention.events",
}

export const DatabaseViewTypes = {
  Table: "table",
  Calendar: "calendar",
  Gantt: "gantt",
  Tasks: "tasks",
  Cards: "cards",
};

export enum ConnectionSecurity {
  TLS = "TLS",
  STARTTLS = "STARTTLS",
  NONE = "None",
  SSL = "SSL",
}

export type DatabaseViewType = keyof typeof DatabaseViewTypes;

export type DatabaseViewConfig = Record<string, any>;

export type PaymentMethod = {
  isActive: boolean;
  id: string;
  paymentGateway: string;
  paymentMethodId: string;
  type: string;
  name: string;
  card: {
    last4: string;
    first6: string;
    card_type: string;
    expiry_year: string;
    expiry_month: string;
    issuer_country: string;
  };
  teamId: string;
  userId: string;
  team: Record<string, string>;
};

export type PublicEnv = {
  URL: string;
  BASENAME_FOR_SUBDOMAIN: string;
  CDN_URL: string;
  COLLABORATION_URL: string;
  AWS_S3_UPLOAD_BUCKET_URL: string;
  AWS_S3_ACCELERATE_URL: string;
  DEPLOYMENT?: string;
  ENVIRONMENT: string;
  SENTRY_DSN?: string;
  TEAM_LOGO?: string;
  SLACK_CLIENT_ID?: string;
  SLACK_APP_ID?: string;
  MAXIMUM_IMPORT_SIZE: number;
  SUBDOMAINS_ENABLED: boolean;
  NOT_ALLOWED_SUBDOMAINS: string;
  EMAIL_ENABLED: boolean;
  DEFAULT_LANGUAGE: string;
  GOOGLE_ANALYTICS_ID?: string;
  YANDEX_METRIKA_ID?: string;
  QUOTA_ENABLED: boolean;
  RELEASE?: string;
  SERVICE_WORKER_PUBLIC_KEY?: string;
  SLACK_KEY?: string;
  IS_PLAYWRIGHT_TESTING?: number;
};

export enum DocumentType {
  Document = "document",
  Database = "database",
  Row = "row",
  Whiteboard = "whiteboard",
  Embed = "embed",
}

export enum ChartPrecision {
  Hour = "hour",
  Day = "day",
  Week = "week",
  Month = "month",
  Year = "year",
}
