import { makeObservable, observable } from "mobx";
import { ProviderType } from "@shared/types";
import type { ProviderData } from "~/stores/BaseProviderStore";
import BaseModel from "~/models/BaseModel";
import Field from "./decorators/Field";

class Provider extends BaseModel {
  constructor(fields: any, store: any) {
    super(fields, store);
    Object.assign(this, fields);
    makeObservable(this);
  }

  @Field
  id: string;

  @Field
  @observable
  teamId: string;

  @Field
  @observable
  createdById: string;

  @Field
  @observable
  name: string;

  @Field
  @observable
  type: ProviderType;

  @Field
  @observable
  isActive: boolean;

  @Field
  @observable
  lastActiveAt: string;

  @Field
  @observable
  settings: ProviderData;

  @Field
  @observable
  createdAt: string;

  @Field
  @observable
  updatedAt: string;

  @Field
  @observable
  deletedAt: string;

  @Field
  @observable
  isDefault: boolean;
}

export default Provider;
