import React, { ComponentProps } from "react";
import Icon from "./Icon/Icon";

type IconProps = ComponentProps<typeof Icon>;

export function MdiSlashForward(props: IconProps) {
  return (
    <Icon {...props}>
      <path d="m7 21l7.9-18H17L9.1 21H7Z"></path>
    </Icon>
  );
}

export default MdiSlashForward;
