import { computed, makeObservable } from "mobx";
import BaseModel from "./BaseModel";

class Membership extends BaseModel {
  constructor(fields: any, store: any) {
    super(fields, store);
    Object.assign(this, fields);
    makeObservable(this);
  }

  id: string;

  userId: string;

  collectionId: string;

  permission: string;

  @computed
  get isEditor(): boolean {
    return this.permission === "read_write";
  }

  @computed
  get isMaintainer(): boolean {
    return this.permission === "maintainer";
  }
}

export default Membership;
