import React, { ComponentProps } from "react";
import Icon from "./Icon/Icon";

type IconProps = ComponentProps<typeof Icon>;

export function MaterialSymbolsNestEcoLeaf(props: IconProps) {
  return (
    <Icon {...props}>
      <path d="M20 4h-8Q8.65 4 6.325 6.325Q4 8.65 4 12q0 1.4.438 2.65q.437 1.25 1.237 2.275L4.3 18.3q-.275.275-.275.7q0 .425.275.7q.275.275.7.275q.425 0 .7-.275l1.4-1.375q1.025.8 2.263 1.237Q10.6 20 12 20q3.35 0 5.675-2.325Q20 15.35 20 12Zm-6.3 6.3q.3.3.3.712q0 .413-.3.713L9.775 15.65q-.3.3-.725.312q-.425.013-.7-.312q-.275-.3-.275-.712q0-.413.275-.688l3.95-3.95q.275-.275.7-.275q.425 0 .7.275Z"></path>
    </Icon>
  );
}

export default MaterialSymbolsNestEcoLeaf;
