export const MOTION_PARAMS = {
  initial: {
    opacity: 0,
    scale: 0.75
  },
  animate: {
    opacity: 1,
    scale: 1
  },
  transition: {
    ease: [0.75, 1.20, 1.30, 1.20],
    duration: 0.15
  }
}

export const BASE_CONTENT_WIDTH = 640;
