import React, { FC, ReactNode } from "react";
import styled from "styled-components";
import Badge from "~/components/Badge";
import CenteredContent from "~/components/CenteredContent";
import Flex from "~/components/Flex";
import Header from "~/components/Header";
import PageTitle from "~/components/PageTitle";

type Props = {
  icon?: ReactNode;
  title?: ReactNode;
  textTitle?: string;
  breadcrumb?: ReactNode;
  actions?: ReactNode;
  centered?: boolean;
  children?: ReactNode;
  isBeta?: boolean;
};

const Scene: FC<Props> = ({
  title,
  textTitle,
  actions,
  breadcrumb,
  children,
  isBeta,
  centered = true,
  icon = null,
}) => {
  return (
    <FillWidth>
      <PageTitle title={textTitle || title} />
      <Header
        hasSidebar
        title={
          <Flex align="center" gap={5}>
            {icon}
            {title}
            {isBeta && (
              <Badge yellow>Beta</Badge>
            )}
          </Flex>
        }
        actions={actions}
        breadcrumb={breadcrumb}
      />
      {centered ? (
        <CenteredContent withStickyHeader>{children}</CenteredContent>
      ) : (
        children
      )}
    </FillWidth>
  );
};

const FillWidth = styled.div`
  width: 100%;
`;

export default Scene;
