import React, { ComponentProps } from 'react'
import Icon from "./Icon/Icon";

type IconProps = ComponentProps<typeof Icon>;

export function IcBaselineWarning(props: IconProps) {
  return (
    <Icon {...props}><path  d="M1 21h22L12 2L1 21zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z"></path></Icon>
  )
}

export default IcBaselineWarning