import * as React from "react";
import {useCallback, useEffect} from "react";
import ym, { YMInitializer } from 'react-yandex-metrika';
import env from "~/env";

export default function Analytics() {
  const appInstalled = useCallback(() => ym("send", "event", "pwa", "install"), []);

  useEffect(() => {
    // Track PWA install event
    window.addEventListener("appinstalled", appInstalled);
    return () => window.removeEventListener("appinstalled", appInstalled);
  }, [appInstalled])

  if (!env.YANDEX_METRIKA_ID ||
    window.location.host.includes('localhost:3000') || window.location.host.includes('127.0.0.1:3000')
  ) {
    return null;
  }

  return <YMInitializer
    accounts={[+env.YANDEX_METRIKA_ID]}
    version="2"
    options={{webvisor: true}}
  />
}
