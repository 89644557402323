import React, { ComponentProps } from "react";
import Icon from "./Icon/Icon";

type IconProps = ComponentProps<typeof Icon>;

export function ClearTextFormat(props: IconProps) {
  return (
    <Icon {...props}>
      <g>
        <path fill="none" d="M0 0h24v24H0z"/>
        <path d="M12.651 14.065L11.605 20H9.574l1.35-7.661-7.41-7.41L4.93 3.515 20.485 19.07l-1.414 1.414-6.42-6.42zm-.878-6.535l.27-1.53h-1.8l-2-2H20v2h-5.927L13.5 9.257 11.773 7.53z"/>
      </g>
    </Icon>
  );
}

export default ClearTextFormat;
