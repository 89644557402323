import React, { ComponentProps } from "react";
import Icon from "./Icon/Icon";

type IconProps = ComponentProps<typeof Icon>;

export function MdiLightningBolt(props: IconProps) {
  return (
    <Icon {...props}>
      <path d="M11 15H6l7-14v8h5l-7 14v-8Z"></path>
    </Icon>
  );
}

export default MdiLightningBolt;
