import { makeObservable } from "mobx";
import BaseStore, { RPCAction } from "~/stores/BaseStore";
import RootStore from "~/stores/RootStore";
import Attachment from "~/models/Attachment";
import { client } from "~/utils/ApiClient";

export default class AttachmentsStore extends BaseStore<Attachment> {
  actions = [RPCAction.Info];

  constructor(rootStore: RootStore) {
    super(rootStore, Attachment);
    makeObservable(this);
  }

  fetchById(id: string) {
    return this.fetch(id);
  }

  async getList(pagination: { offset: number; limit: number }) {
    const attachmentsList = await client.post(`/attachments.list`, {
      offset: pagination.offset,
      limit: pagination.limit,
    });
    return attachmentsList;
  }

  async getFilesSize() {
    const attachmentsListSize = await client.post(`/attachments.size`);
    return attachmentsListSize.size;
  }

  async deleteFile(id: string) {
    await client.post(`/attachments.delete`, {
      id: id,
    });
  }
}
