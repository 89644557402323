import * as React from "react";
import Frame from "../components/Frame";
import { EmbedProps as Props } from ".";

enum LINK_TYPE {
  PLAYLISTS = "playlists",
  ALBUM = "album",
  TRACK = "track",
}

const BASE_LINK = "https://music.yandex.ru/iframe";

const ALBUM = new RegExp("https:\/\/music\.yandex\.ru\/(album)\/([0-9]+)?");
const TRACK = new RegExp("https:\/\/music\.yandex\.ru\/album\/([0-9]+)\/(track)\/([0-9]+)?");
const PLAYLIST = new RegExp("https:\/\/music\.yandex\.ru\/users\/([a-zA-Z0-9._]+)\/(playlists)\/([0-9]+)?");

export default class YandexMusic extends React.Component<Props> {
  static ENABLED = [TRACK, ALBUM, PLAYLIST];

  getType(matches: RegExpMatchArray) {
    if (matches.includes(LINK_TYPE.TRACK)) {
      return LINK_TYPE.TRACK;
    }

    if (matches.includes(LINK_TYPE.ALBUM)) {
      return LINK_TYPE.ALBUM;
    }

    if (matches.includes(LINK_TYPE.PLAYLISTS)) {
      return LINK_TYPE.PLAYLISTS;
    }

    return "";
  }

  getLink(matches: RegExpMatchArray) {
    const type = this.getType(matches);

    switch (type) {
      case LINK_TYPE.PLAYLISTS:
        return `${BASE_LINK}/playlist/${matches[1]}/${matches[3]}`;
      case LINK_TYPE.ALBUM:
        return `${BASE_LINK}/album/${matches[2]}`;
      case LINK_TYPE.TRACK:
        return `${BASE_LINK}/track/${matches[3]}/${matches[1]}`;
      default:
        return BASE_LINK;
    }
  }

  render() {
    const { matches } = this.props.attrs;

    return (
      <Frame
        {...this.props}
        src={this.getLink(matches)}
        title="Yandex Music"
      />
    );
  }
}
