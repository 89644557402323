import React, { ComponentProps } from "react";
import Icon from "./Icon/Icon";

type IconProps = ComponentProps<typeof Icon>;

export function MdiPublishOff(props: IconProps) {
  return (
    <Icon {...props}>
      <path d="M20.8 22.7L15 16.9V20H9v-6H5l3.6-3.6L1.1 3l1.3-1.3l19.7 19.7l-1.3 1.3M19 6V4H7.2l2 2H19m-1.8 8H19l-7-7l-.9.9l6.1 6.1Z"></path>
    </Icon>
  );
}

export default MdiPublishOff;
