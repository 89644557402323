import React, { ComponentProps } from "react";
import Icon from "./Icon/Icon";

type IconProps = ComponentProps<typeof Icon>;

export function CarbonCaretSort(props: IconProps) {
  return (
    <Icon {...props}>
      <path d="M9 3L5 6.99h3V14h2V6.99h3L9 3zm7 14.01V10h-2v7.01h-3L15 21l4-3.99h-3z"></path>
    </Icon>
  );
}

export default CarbonCaretSort;
