import { makeObservable, observable, override } from "mobx";
import BaseModel from "./BaseModel";
import Field from "./decorators/Field";

class NotificationSetting extends BaseModel {
  constructor(fields: any, store: any) {
    super(fields, store);
    Object.assign(this, fields);
    makeObservable(this);
  }

  @Field
  @override
  id: string;

  @Field
  @observable
  event: string;

  @Field
  @observable
  type: string;
}

export default NotificationSetting;
