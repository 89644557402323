import React, { ComponentProps } from "react";
import Icon from "./Icon/Icon";

type IconProps = ComponentProps<typeof Icon>;

export function IcBaselineHorizontalRule(props: IconProps) {
  return (
    <Icon {...props}>
      <path fillRule="evenodd" d="M4 11h16v2H4z"></path>
    </Icon>
  );
}

export default IcBaselineHorizontalRule;
