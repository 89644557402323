import i18n from "i18next";
import { client } from "~/utils/ApiClient";

type TPart = {
  PartNumber: number;
  signedUrl: string;
};

type TUploadedPart = {
  PartNumber: number;
  ETag: string;
}

export type TProgress = {
  sent: number;
  total: number;
  percentage: number;
}

export type TOptions = {
  file: File;
  threadsQuantity?: number;
  public?: boolean;
  chunkSize?: number;
  documentId?: string;
  size?: number;
}

export class FileUploader {
  chunkSize: number;
  file: File;
  fileName: string;
  fileSize: number;
  activeConnections: object;
  parts: TPart[];
  aborted: boolean;
  fileKey: string;
  uploadId: string;
  uploadedSize: number;
  threadsQuantity: number;
  uploadedParts: TUploadedPart[];
  progressCache: Record<number, number>;
  onProgressFn: (data?: unknown) => void;
  onErrorFn: (data?: Error) => void;
  onCompleteFn: (data?: unknown) => void;
  bucket: string;
  attachment: any;
  public?: boolean;
  documentId?: string;

  constructor(options: TOptions) {
    this.chunkSize = options.chunkSize || 1024 * 1024 * 1000;
    this.file = options.file;
    this.public = options.public;
    this.documentId = options.documentId;
    this.fileName = options.file.name;
    this.fileSize = options.file.size;
    this.fileKey = this.fileName;
    this.threadsQuantity = Math.min(options.threadsQuantity || 5, 15);
    this.aborted = false;
    this.activeConnections = {};
    this.progressCache = {};
    this.parts = [];
    this.uploadedParts = [];
    this.uploadedSize = 0;
    this.onProgressFn = () => undefined;
    this.onErrorFn = () => undefined;
    this.onCompleteFn = () => undefined;
  }

  async start() {
    await this.initialize();
  }

  async initialize() {
    try {
      const initializeResponse = await client.post("/attachments.multipart_create", {
        name: this.fileName,
        public: this.public,
        documentId: this.documentId,
        size: this.fileSize,
        contentType: this.file.type,
      });

      const AWSFileDataOutput = initializeResponse.data;
      const partsNumber = Math.ceil(this.file.size / this.chunkSize);

      this.uploadId = AWSFileDataOutput.uploadId;
      this.fileKey = AWSFileDataOutput.form.key;
      this.bucket = AWSFileDataOutput.form.bucket;
      this.attachment = AWSFileDataOutput.attachment;

      const urlsResponse = await client.post("/attachments.multipart_upload", {
        parts: partsNumber,
        uploadId: AWSFileDataOutput.uploadId,
        bucket: AWSFileDataOutput.form.bucket,
        key: AWSFileDataOutput.form.key,
      });
  
      const newParts = urlsResponse.data.signedUrl;
      this.parts.push(...newParts);

      this.sendNext();
    } catch (error) {
      await this.complete(error);
    }
  }

  sendNext() {
    const activeConnections = Object.keys(this.activeConnections).length

    if (activeConnections >= this.threadsQuantity) {
      return;
    }

    if (!this.parts.length) {
      if (!activeConnections) {
        this.complete();
      }

      return;
    }

    const part = this.parts.pop();
    if (this.file && part) {
      const sentSize = (part.PartNumber - 1) * this.chunkSize;
      const chunk = this.file.slice(sentSize, sentSize + this.chunkSize);

      const sendChunkStarted = () => {
        this.sendNext();
      }

      this.sendChunk(chunk, part, sendChunkStarted)
        .then(() => {
          this.sendNext();
        })
        .catch((error) => {
          this.parts.push(part);
          this.complete(error);
        });
    }
  }

  async complete(error?: Error) {
    if (error && !this.aborted) {
      this.onErrorFn(error);
      return;
    }

    if (error) {
      this.onErrorFn(error);
      return;
    }

    try {
      await this.sendCompleteRequest();
      this.onCompleteFn();
    } catch (error) {
      this.onErrorFn(error);
    }
  }

  async sendChunk(chunk: Blob, part: TPart, sendChunkStarted: () => void) {
    return new Promise<void>((resolve, reject) => {
      this.upload(chunk, part, sendChunkStarted)
        .then((status) => {
          if (status !== 200) {
            reject(new Error("Failed chunk upload"));
            return;
          }

          resolve();
        })
        .catch((error) => {
          reject(error);
        })
    })
  }

  async sendCompleteRequest() {
    if (this.fileKey) {
      await client.post("/attachments.multipart_complete", {
        Bucket: this.bucket,
        Key: this.fileKey,
        UploadId: this.uploadId,
        MultipartUpload: {
          Parts: this.uploadedParts.sort((a, b) => a.PartNumber - b.PartNumber),
        },
      });

    }
  }

  async upload(file: Blob, part: TPart, sendChunkStarted: () => void) {
    return new Promise((resolve, reject) => {
      if (this.fileKey) {
        // - 1 because PartNumber is an index starting from 1 and not 0
        const xhr = (this.activeConnections[part.PartNumber - 1] = new XMLHttpRequest());

        sendChunkStarted();

        const progressListener = this.handleProgress.bind(this, part.PartNumber - 1);

        xhr.upload.addEventListener("progress", progressListener);

        xhr.addEventListener("error", progressListener);
        xhr.addEventListener("abort", progressListener);
        xhr.addEventListener("loadend", progressListener);

        xhr.open("PUT", part.signedUrl);

        xhr.onreadystatechange = () => {
          if (xhr.readyState === 4 && xhr.status === 200) {
            // retrieving the ETag parameter from the HTTP headers
            const ETag = xhr.getResponseHeader("ETag");

            if (ETag) {
              const uploadedPart = {
                PartNumber: part.PartNumber,
                // removing the " enclosing carachters from
                // the raw ETag
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                ETag: ETag.replaceAll('"', ""),
              }

              this.uploadedParts.push(uploadedPart);

              resolve(xhr.status);
              delete this.activeConnections[part.PartNumber - 1];
            }
          }
        }

        xhr.onerror = (error) => {
          reject(error);
          delete this.activeConnections[part.PartNumber - 1];
        }

        xhr.onabort = () => {
          reject(new Error(i18n.t("Upload cancelled by user")));
          delete this.activeConnections[part.PartNumber - 1];
        }

        xhr.send(file);
      }
    });
  }

  handleProgress(part: number, event: ProgressEvent) {
    if (this.file) {
      if (event.type === "progress" || event.type === "error" || event.type === "abort") {
        this.progressCache[part] = event.loaded;
      }

      if (event.type === "uploaded") {
        this.uploadedSize += this.progressCache[part] || 0
        delete this.progressCache[part]
      }

      const inProgress = Object.keys(this.progressCache)
        .map(Number)
        .reduce((memo, id) => (memo += this.progressCache[id]), 0);

      const sent = Math.min(this.uploadedSize + inProgress, this.file.size)

      const total = this.file.size

      const percentage = Math.round((sent / total) * 100)

      this.onProgressFn({
        sent,
        total,
        percentage,
      })
    }
  }

  onProgress(onProgress: (data: TProgress) => void) {
    this.onProgressFn = onProgress;
    return this;
  }

  onError(onError: (error: Error) => void) {
    this.onErrorFn = onError;
    return this;
  }

  onComplete(onComplete: () => void) {
    this.onCompleteFn = onComplete;
    return this;
  }

  async abort() {
    Object.keys(this.activeConnections)
      .map(Number)
      .forEach((id) => {
        this.activeConnections[id].abort();
      });

    await client.post("/attachments.multipart_abort", {
      Bucket: this.bucket,
      Key: this.fileKey,
      UploadId: this.uploadId,
    });

    this.aborted = true;
  }
}
