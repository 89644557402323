import React, { ComponentProps } from "react";
import Icon from "./Icon/Icon";

type IconProps = ComponentProps<typeof Icon>;

export function IcBaselineCheck(props: IconProps) {
  return (
    <Icon {...props}>
      <path d="M9 16.17L4.83 12l-1.42 1.41L9 19L21 7l-1.41-1.41z"></path>
    </Icon>
  );
}

export default IcBaselineCheck;
