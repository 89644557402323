import * as React from "react";
import Frame from "../components/Frame";
import { EmbedProps as Props } from ".";

const URL_REGEX = new RegExp("https://airtable.com/(?:embed/)?(shr.*)$");
const NEW_URL_REGEX = new RegExp("https:\\/\\/www\\.airtable\\.com\\/embed\\/[A-Za-z0-9]+\\/[A-Za-z0-9]+");

export default class Airtable extends React.Component<Props> {
  static ENABLED = [URL_REGEX, NEW_URL_REGEX];

  render() {
    const { matches } = this.props.attrs;
    const shareId = matches[1];
    return (
      <Frame
        {...this.props}
        src={this.props.attrs.href}
        title={`Airtable (${shareId})`}
        border
      />
    );
  }
}
