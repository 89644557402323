import React, { ComponentProps } from "react";
import Icon from "./Icon/Icon";

type IconProps = ComponentProps<typeof Icon>;

export function TablerTargetArrow(props: IconProps) {
  return (
    <Icon {...props}>
      <g fill="none" stroke={props.color || "#333"} strokeLinejoin="round" strokeWidth="2">
        <circle cx="12" cy="12" r="1"></circle>
        <path d="M12 7a5 5 0 1 0 5 5"></path>
        <path d="M13.004 3.055A9 9 0 1 0 20.945 11"></path>
        <path d="M15 6v3h3l3-3h-3V3zm0 3l-3 3"></path>
      </g>
    </Icon>
  );
}

export default TablerTargetArrow;
