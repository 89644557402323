import React, { ComponentProps } from "react";
import Icon from "./Icon/Icon";

type IconProps = ComponentProps<typeof Icon>;

export function IcBaselineRepeat(props: IconProps) {
  return (
    <Icon {...props}>
      <path d="M7 7h10v3l4-4l-4-4v3H5v6h2V7zm10 10H7v-3l-4 4l4 4v-3h12v-6h-2v4z"></path>
    </Icon>
  );
}

export default IcBaselineRepeat;
