import React, { ComponentProps } from "react";
import Icon from "./Icon/Icon";

type IconProps = ComponentProps<typeof Icon>;

export function IcBaselineArrowBackIosNew(props: IconProps) {
  return (
    <Icon {...props}>
      <path d="M17.77 3.77L16 2L6 12l10 10l1.77-1.77L9.54 12z"></path>
    </Icon>
  );
}

export default IcBaselineArrowBackIosNew;
