import Tippy, { TippyProps } from "@tippy.js/react";
import { TFunctionResult } from "i18next";
import * as React from "react";
import styled, { css } from "styled-components";

export type Props = Omit<TippyProps, "content" | "theme"> & {
  tooltip: React.ReactChild | React.ReactChild[] | TFunctionResult;
  shortcut?: React.ReactNode;
  reverseColor?: boolean;
};

function Tooltip({
  shortcut,
  tooltip,
  delay = 50,
  reverseColor = true,
  ...rest
}: Props) {
  let content = <>{tooltip}</>;

  if (!tooltip) {
    return rest.children;
  }

  if (shortcut) {
    content = (
      <>
        {tooltip} &middot; <Shortcut $reverseColor={reverseColor}>{shortcut}</Shortcut>
      </>
    );
  }

  return (
    <StyledTippy
      arrow
      arrowType="round"
      animation="shift-away"
      content={content}
      delay={delay}
      $reverseColor={reverseColor}
      duration={[200, 150]}
      inertia
      {...rest}
    />
  );
}

const Shortcut = styled.kbd<{ $reverseColor: boolean }>`
  position: relative;
  top: -2px;

  display: inline-block;
  padding: 2px 4px;
  font: 10px "SFMono-Regular", Consolas, "Liberation Mono", Menlo, Courier,
    monospace;
  line-height: 10px;
  color: ${({ theme, $reverseColor }) => $reverseColor ? theme.tooltipBackground : theme.menuBackground};
  vertical-align: middle;
  background-color: ${({ theme }) => theme.tooltipText};
  border-radius: 3px;
`;

const StyledTippy = styled(Tippy)<{ $reverseColor: boolean }>`
  font-size: 13px;
  background-color: ${({ theme, $reverseColor }) => $reverseColor ? theme.tooltipBackground : theme.menuBackground};
  color: ${({ theme }) => theme.tooltipText};
  
  ${({ $reverseColor, theme }) => !$reverseColor && css`
    box-shadow: ${theme.menuShadow}};
  `}

  svg {
    fill: ${({ theme, $reverseColor }) => $reverseColor ? theme.tooltipBackground : theme.menuBackground};
  }
`;

export default Tooltip;
