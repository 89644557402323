import React, { ComponentProps } from "react";
import Icon from "./Icon/Icon";

type IconProps = ComponentProps<typeof Icon>;

export function TablerAlignRight(props: IconProps) {
  return (
    <Icon {...props}>
      <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16m-10 6h10M6 18h14"></path>
    </Icon>
  );
}

export default TablerAlignRight;
