import { ComputedModeStyle, ComputedSizeStyle } from "@uikit/Button/types";

export const modeStyle = (danger?: boolean): ComputedModeStyle => ({
  primary: {
    backgroundColor: !danger ?  "buttonPrimaryBackground" : "danger",
    hoverBackground: !danger ? "buttonPrimaryHoverBackground" : "buttonDangerHoverBackground",
    borderColor: "transparent",
    hoverBorderColor: "transparent",
    color: "buttonColor",
    hoverColor: "buttonHoverColor",
    boxShadow: "buttonBoxShadow",
  },
  danger: {
    backgroundColor: "danger",
    hoverBackground: "buttonDangerHoverBackground",
    borderColor: "transparent",
    hoverBorderColor: "transparent",
    color: "buttonColor",
    hoverColor: "buttonHoverColor",
    boxShadow: "buttonBoxShadow",
  },
  neutral: {
    backgroundColor: "buttonNeutralBackground",
    hoverBackground: !danger ? "buttonNeutralHoverBackground" : "buttonDangerHoverBackground",
    borderColor: !danger ? "buttonNeutralBorderColor" : "buttonDangerHoverBackground",
    hoverBorderColor: !danger ? "buttonNeutralBorderColor" : "transparent",
    color: !danger ? "buttonNeutralColor" : "danger",
    hoverColor: !danger ? "buttonNeutralColor" : "buttonNeutralHoverColor",
    boxShadow: "none",
  },
  flat: {
    backgroundColor: "transparent",
    hoverBackground: !danger ? "buttonNeutralHoverBackground" : "buttonDangerHoverBackground",
    borderColor: "transparent",
    hoverBorderColor: "transparent",
    boxShadow: "none",
    color: !danger ? "textTertiary" : "danger",
    hoverColor: !danger ? "textTertiary" : "buttonNeutralHoverColor"
  },
  link: {
    backgroundColor: "transparent",
    hoverBackground: "transparent",
    borderColor: "transparent",
    hoverBorderColor: "transparent",
    boxShadow: "none",
    color: !danger ? "buttonPrimaryBackground" : "danger",
    hoverColor: !danger ? "buttonLinkHoverColor" : "buttonDangerHoverBackground",
  },
});

export const sizeStyle = (iconOnly?: boolean): ComputedSizeStyle => ({
  small: {
    padding: iconOnly ? "0" : "2px 6px",
    width: iconOnly ? "22px" : "auto",
    fontSize: "12px",
    height: "22px",
    svgSize: "18px",
  },
  middle: {
    padding: iconOnly ? "0" : "6px 10px",
    width: iconOnly ? "32px" : "auto",
    fontSize: "14px",
    height: "32px",
    svgSize: "20px",
  },
  large: {
    padding: iconOnly ? "0" : "10px 14px",
    width: iconOnly ? "40px" : "auto",
    fontSize: "16px",
    height: "40px",
    svgSize: "24px",
  },
});
