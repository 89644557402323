import { CustomOidcSettings, ProviderType } from "@shared/types";
import BaseProviderStore, { CommonData } from "~/stores/BaseProviderStore";

export type OidcData = CustomOidcSettings & CommonData;

export default class ProviderOIDCStore extends BaseProviderStore {
  ENDPOINT = ProviderType.OIDC;
  DEFAULT_BUTTON_NAME = "Login with OIDC";
  data: OidcData;

  constructor() {
    super();
  }
}