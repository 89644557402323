import React from "react";
import { IconProps } from ".";

const Icon = ({ children, className, onClick, theme, ...props }: IconProps) => {
    const size = props.size ? props.size + "px" : "20px";
    let fill = "#4E5C6E";

    if (props.color) {
        fill = props.color;
    }
    if (props.light) {
        fill = "#FFF";
    }
    if (props.black) {
        fill = "#123";
    }

    return (
      <svg
        fill={fill}
        width={size}
        height={size}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
      >
        {children}
      </svg>
    );
  }

  export default Icon;