import React, { ComponentProps } from 'react'
import Icon from './Icon/Icon';

type IconProps = ComponentProps<typeof Icon>;

export function IcBaselinePlus(props: IconProps) {
  return (
    <Icon {...props}><path  d="M19 12.998h-6v6h-2v-6H5v-2h6v-6h2v6h6z"></path></Icon>
  )
}

export default IcBaselinePlus