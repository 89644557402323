import { makeObservable } from "mobx";
import Field from "~/models/decorators/Field";
import BaseModel from "./BaseModel";

class Group extends BaseModel {
  constructor(fields: any, store: any) {
    super(fields, store);
    Object.assign(this, fields);
    makeObservable(this);
  }

  @Field
  id: string;

  @Field
  name: string;

  memberCount: number;

  updatedAt: string;
}

export default Group;
