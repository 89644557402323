import * as React from "react";
import Frame from "../components/Frame";
import { EmbedProps as Props } from ".";

const URL_REGEX = /https:\/\/kinescope\.io\/([0-9]{9})/i;
const EMBED_REGEX = /https:\/\/kinescope\.io\/embed\/([0-9]{9})/i;

export default class Kinescope extends React.Component<Props> {
  static ENABLED = [URL_REGEX, EMBED_REGEX];

  render() {
    const { matches } = this.props.attrs;
    const id = matches[1];

    return (
      <Frame
        {...this.props}
        src={`https://kinescope.io/embed/${id}`}
        title="Kinescope embed"
        border
      />
    );
  }
}
