import React, { ReactElement } from "react";
import styled from "styled-components";
import Flex from "~/components/Flex";
import { CloseIcon } from "~/components/Icons";
import Button from "@uikit/Button";

type Props = {
  title: ReactElement | string;
  onRequestClose: () => void;
  showCloseButton: boolean;
}

const Header = ({ title, onRequestClose, showCloseButton }: Props) => {
  return (
    <HeaderWrapper align="center" gap={5}>
      {title}
      {showCloseButton && (
        <Button
          onMouseDown={onRequestClose}
          icon={<CloseIcon color="currentColor" />}
          mode="flat"
        />
      )}
    </HeaderWrapper>
  );
}

const HeaderWrapper = styled(Flex)`
  color: ${(props) => props.theme.textSecondary};
  font-weight: 600;
  padding: 0 0 12px 0;
  
  button {
    margin-left: auto;
    align-self: flex-start;
    flex-shrink: 0;
  }
`;

export default Header;
