import i18next from "i18next";
import { ProviderType, SamlSettings } from "@shared/types";
import BaseProviderStore, { CommonData } from "~/stores/BaseProviderStore";
import { PermissionsListType } from "~/scenes/Settings/Authentication/Providers/components/Form/Permissions";

export type SamlData = SamlSettings & CommonData & {
  cert?: string;
  providerId?: string;
};

export default class ProviderSAMLStore extends BaseProviderStore {
  ENDPOINT = ProviderType.SAML;
  DEFAULT_BUTTON_NAME = "Login with SAML";
  data: SamlData;

  constructor() {
    super();
  }

  getPermissionForRequest(permissions: PermissionsListType[]) {
    return permissions
      .map(permission => ({
        isEqual: ["=", "!="].includes(permission.condition) ? permission.condition === "=" : undefined,
        isIn: ["in", "nin"].includes(permission.condition) ? permission.condition === "in" : undefined,
        isInArray: ["inArr", "ninArr"].includes(permission.condition) ? permission.condition === "inArr" : undefined,
        attributeName: permission.attributeName,
        attributeValue: permission.attributeValue,
        condition: permission.condition,
        isAllowed: typeof permission.isAllowed === "string" ? JSON.parse(permission.isAllowed) : permission.isAllowed,
      }));
  }

  getFormDataKeyValue(key: string, data: SamlData) {
    switch (key) {
      case "permissions": {
        const permissionsForRequest = this.getPermissionForRequest(this.data[key as string]);
        const filtersNotEmpty = permissionsForRequest.length > 0;
        let hasAttributeName: boolean;

        if (filtersNotEmpty) {
          hasAttributeName = Boolean(permissionsForRequest[0].attributeName.trim());
        } else {
          hasAttributeName = false;
        }

        if (filtersNotEmpty && hasAttributeName) {
          return { formKey: "filters", formValue: JSON.stringify(permissionsForRequest) };
        }

        return { formKey: "filters", formValue: JSON.stringify([]) };
      }
      case "customButtonName": {
        const text = this.data[key as string]?.trim();
        if (text) {
          return { formKey: key, formValue: text };
        }

        return { formKey: key, formValue: i18next.t(this.DEFAULT_BUTTON_NAME) };
      }
      case "cert": {
        const value = data[key];
        return {
          formKey: key,
          formValue: typeof value === "string" ? value : (data[key] as any)?.fileList[0].originFileObj,
        };
      }
      case "isActive":
        return { formKey: "isActive", formValue: String(data[key] ?? false) }
      case "isDefault":
        return { formKey: "isDefault", formValue: String(data[key] ?? false) }
      default:
        return { formKey: key, formValue: data[key] };
    }
  }

  getFormData() {
    const formData = new FormData();
    if (this.data.id) {
      formData.append("providerId", this.data.id)
    }

    for (const key in this.data) {
      const { formKey, formValue } = this.getFormDataKeyValue(key, this.data);

      if (formValue) {
        formData.append(formKey, formValue);
      }
    }

    return formData;
  }
}
