import React, { ComponentProps } from "react";
import Icon from "./Icon/Icon";

type IconProps = ComponentProps<typeof Icon>;

export function FeBrowser(props: IconProps) {
  return (
    <Icon {...props}>
      <path
        d="M4 10v8h16v-8H4Zm0-4v2h2V6H4Zm4 0v2h12V6H8ZM4 4h16a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2Z"
      ></path>
    </Icon>
  );
}

export default FeBrowser;
