import React, { ComponentProps } from "react";
import Icon from "./Icon/Icon";

type IconProps = ComponentProps<typeof Icon>;

export function IcSharpFormatQuote(props: IconProps) {
  return (
    <Icon {...props}>
      <path d="M5 17h3l2-4V7H4v6h3l-2 4zm10 0h3l2-4V7h-6v6h3l-2 4z"></path>
    </Icon>
  );
}

export default IcSharpFormatQuote;
