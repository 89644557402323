import { makeObservable } from "mobx";
import BaseModel from "./BaseModel";

class ApiKey extends BaseModel {
  constructor(fields: any, store: any) {
    super(fields, store);
    Object.assign(this, fields);
    makeObservable(this);
  }

  id: string;

  name: string;

  secret: string;
}

export default ApiKey;
