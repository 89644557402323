import { action, makeObservable, observable } from "mobx";
import { FileUploader } from "~/utils/FileUploader";
import type { TProgress } from "~/utils/FileUploader";

type TFileUploaderInitialize = {
  file: File;
}

type TFileUploaderHandlers = {
  startHandler?: () => void;
  completeHandler?: () => void;
  errorHandler?: (error?: Error) => void;
}

type TFileUploaderData =  TFileUploaderInitialize & TFileUploaderHandlers;

export enum UploadedStatus {
  NOT_STARTED = "not_started",
  PROGRESS = "progress",
  DONE = "done",
  ERROR = "error",
}

export default class UploadStore {
  @observable
  sent = 0;

  @observable
  total = 0;

  @observable
  percentage = 0;

  @observable
  attachment: any = null;

  @observable
  fileName = "";

  @observable
  status: UploadedStatus = UploadedStatus.NOT_STARTED;

  @observable
  showUploadingInfo = false;

  private uploader: FileUploader | null = null;

  constructor() {
    makeObservable(this);
  }

  async uploadFile(data: TFileUploaderData) {
    this.setFileName(data.file.name);

    this.uploader = new FileUploader({
      file: data.file,
    });

    await this.start({
      startHandler: data.startHandler,
      completeHandler: data.completeHandler,
      errorHandler: data.errorHandler,
    });
  }

  @action.bound
  async start(data: TFileUploaderHandlers) {
    if (!this.uploader) {
      return;
    }

    this.uploader
      .onProgress(this.setUploadState)
      .onComplete(() => {
        this.setUploadStatus(UploadedStatus.DONE);
        data.completeHandler?.();
        this.setShowUploadingInfo(false);
        window.onbeforeunload = null;
        this.clear();
      })
      .onError((error) => {
        this.setUploadStatus(UploadedStatus.ERROR);
        data.errorHandler?.(error);
        this.clear();
      });

    window.onbeforeunload = () => "";
    this.setUploadStatus(UploadedStatus.PROGRESS);

    data.startHandler?.();
    await this.uploader.start();
    this.attachment = this.uploader.attachment;
  }

  abortUploading() {
    if (!this.uploader) {
      return;
    }

    window.onbeforeunload = null;
    this.uploader.abort();
    this.clear();
  }

  @action.bound
  setUploadState(state: TProgress) {
    this.sent = state.sent;
    this.total = state.total;
    this.percentage = state.percentage;
  }

  @action.bound
  setUploadStatus(status: UploadedStatus) {
    this.status = status;
  }

  @action.bound
  setShowUploadingInfo(state: boolean) {
    if (state && this.status !== UploadedStatus.PROGRESS) {
      return;
    }

    this.showUploadingInfo = state;
  }

  @action.bound
  setFileName(value: string) {
    this.fileName = value;
  }

  @action.bound
  clear() {
    this.sent = 0;
    this.percentage = 0;
    this.total = 0;
    this.attachment = null;
    this.fileName = "";
    this.status = UploadedStatus.NOT_STARTED;
    this.showUploadingInfo = false;
    this.uploader = null;
  }
}