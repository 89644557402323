import React, { ComponentProps } from "react";
import Icon from "./Icon/Icon";

type IconProps = ComponentProps<typeof Icon>;

export function IcSharpNightlightRound(props: IconProps) {
  return (
    <Icon {...props}>
      <path d="M15.5 22c1.05 0 2.05-.16 3-.46c-4.06-1.27-7-5.06-7-9.54s2.94-8.27 7-9.54c-.95-.3-1.95-.46-3-.46c-5.52 0-10 4.48-10 10s4.48 10 10 10z"></path>
    </Icon>
  );
}

export default IcSharpNightlightRound;
