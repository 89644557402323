import { format, parse, set } from "date-fns";
import { Value as RangeValue } from "~/components/Analytics/Range";

const time = {
  hours: 23,
  minutes: 59,
  seconds: 59,
}

export function prepareOptionsToRequest(range: RangeValue) {
  return {
    from: convertDate(range.from, "yyyy/MM/dd"),
    to: convertDate(range.to),
    precision: range.precision,
  }
}

function convertDate(
  date?: Date | string | number,
  returnDateFormat = "yyyy/MM/dd HH:mm:ss",
  dateFormat = "dd.MM.yyyy",
) {
  if (!date) {
    return format(set(new Date(), time), returnDateFormat);
  }

  if (typeof date === "string") {
    const dateFromString = parse(date, dateFormat, new Date());
    return format(set(dateFromString, time), returnDateFormat);
  }

  return format(set(new Date(date), time), returnDateFormat);
}
