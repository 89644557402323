import { PropsWithChildren, useCallback, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { useTheme } from "styled-components";
import { useVh } from "~/hooks/useVh";
import getContainerCSSText from "@uikit/Modal/utils/getContainerCSSText";

type Props = {
  onRequestClose: () => void;
  closeForEscape: boolean;
  closeForOutsideClick: boolean;
}

const Portal = ({
  onRequestClose,
  closeForEscape,
  closeForOutsideClick,
  children
}: PropsWithChildren<Props>) => {
  const vh = useVh();
  const theme = useTheme();

  const [container] = useState(() => {
    const container = document.createElement("div");
    container.setAttribute("data-enter", "modal");
    container.style.cssText = getContainerCSSText(theme, vh);
    return container;
  });

  const clickHandler = useCallback((event: MouseEvent) => {
    if (!event.target || !closeForOutsideClick ) {
      return;
    }

    if ((event.target as HTMLElement).getAttribute("data-enter") === "modal") {
      onRequestClose();
    }
  }, [onRequestClose, closeForOutsideClick]);

  const keyDownHandler = useCallback((event: KeyboardEvent) => {
    if (event.key !== "Escape" || !closeForEscape) {
      return
    }

    const modals = document.querySelectorAll("[data-enter='modal'][data-close-for-escape]");

    // Если открыто несколько модальных окон, то при нажатии Esc, они будут закрыты одновременно все.
    // Это некорректное поведение и проверка ниже нужна для его предотвращения. Благодаря ей в такой
    // ситуации закрывается только последнее открытое окно
    if (modals && Array.from(modals).slice(-1)[0] !== container) {
      return;
    }

    onRequestClose();
  }, [closeForEscape, container, onRequestClose]);

  useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      const modals = document.querySelectorAll("[data-enter='modal']");

      // Возвращаем полосы прокрутки документа только тогда, когда закрывается именно последняя открытая модалка
      if (modals && Array.from(modals).length === 1) {
        document.body.style.overflow = "";
      }
    }
  }, [container]);

  useEffect(() => {
    // Помечаем модальные окна, которые могут быть закрыты с помощью Escape
    if (closeForEscape) {
      container.setAttribute("data-close-for-escape", "true");
    } else {
      container.removeAttribute("data-close-for-escape");
    }
  }, [closeForEscape, container]);

  useEffect(() => {
    container.addEventListener("click", clickHandler);
    window.addEventListener("keydown", keyDownHandler, true);

    return () => {
      container.removeEventListener("click", clickHandler);
      window.removeEventListener("keydown", keyDownHandler);
    }
  }, [clickHandler, container, keyDownHandler]);

  useEffect(() => {
    document.body.appendChild(container);
    return () => {
      document.body.removeChild(container);
    };
  }, [container]);

  return createPortal(children, container);
}

export default Portal;
