import invariant from "invariant";
import { concat, find, last } from "lodash";
import { computed, action, override, makeObservable } from "mobx";
import Collection from "~/models/Collection";
import DocumentModel from "~/models/Document";
import User from "~/models/User";
import { prepareOptionsToRequest } from "~/components/Analytics/utils";
import { NavigationNode } from "~/types";
import { client } from "~/utils/ApiClient";
import { AuthorizationError, NotFoundError } from "~/utils/errors";
import BaseStore from "./BaseStore";
import RootStore from "./RootStore";

enum DocumentPathItemType {
  Collection = "collection",
  Document = "document",
}

export type DocumentPathItem = {
  type: DocumentPathItemType;
  id: string;
  collectionId: string;
  title: string;
  url: string;
};

export type DocumentPath = DocumentPathItem & {
  path: DocumentPathItem[];
};

export type TCollectionUsers = {
  collectionUsers: User[];
  otherUsers: User[];
};

export type TCollectionUsersWithTotal = {
  data: TCollectionUsers;
  total: number;
}

export default class CollectionsStore extends BaseStore<Collection> {
  constructor(rootStore: RootStore) {
    super(rootStore, Collection);
    makeObservable(this);
  }

  @computed
  get active(): Collection | null | undefined {
    return this.rootStore.ui.activeCollectionId
      ? this.data.get(this.rootStore.ui.activeCollectionId)
      : undefined;
  }

  @override
  get orderedData(): Collection[] {
    let collections = Array.from(this.data.values());
    collections = collections.filter((collection) =>
      collection.deletedAt ? false : true
    );
    return collections.sort((a, b) => {
      if (a.index === b.index) {
        return a.updatedAt > b.updatedAt ? -1 : 1;
      }

      return a.index < b.index ? -1 : 1;
    });
  }

  /**
   * List of paths to each of the documents, where paths are composed of id and title/name pairs
   */
  @computed
  get pathsToDocuments(): DocumentPath[] {
    const results: DocumentPathItem[][] = [];

    const travelDocuments = (
      documentList: NavigationNode[],
      collectionId: string,
      path: DocumentPathItem[]
    ) =>
      documentList.forEach((document: NavigationNode) => {
        const { id, title, url } = document;
        const node = {
          type: DocumentPathItemType.Document,
          id,
          collectionId,
          title,
          url,
        };
        results.push(concat(path, node));
        travelDocuments(document.children, collectionId, concat(path, [node]));
      });

    if (this.isLoaded) {
      this.data.forEach((collection) => {
        const { id, name, url } = collection;
        const node = {
          type: DocumentPathItemType.Collection,
          id,
          collectionId: id,
          title: name,
          url,
        };
        results.push([node]);
        travelDocuments(collection.documents, id, [node]);
      });
    }

    return results.map((result) => {
      const tail = last(result) as DocumentPathItem;
      return { ...tail, path: result };
    });
  }

  @action
  import = async (attachmentId: string, type = 'yonote', format?: string) => {
    await client.post("/collections.import", {
      type,
      format,
      attachmentId,
    });
  };

  @action
  move = async (collectionId: string, index: string) => {
    const res = await client.post("/collections.move", {
      id: collectionId,
      index,
    });
    invariant(res?.success, "Collection could not be moved");
    const collection = this.get(collectionId);

    if (collection) {
      collection.updateIndex(res.data.index);
    }
  };

  @action
  fetchAnalytics = async (
    collectionId: string,
    options: {
      from: Date | string | number,
      to?: Date | string | number,
      limit?: number,
    }
  ) => {
    const preparedOptions = prepareOptionsToRequest(options);

    const topDocuments = await this.fetchTopDocuments(collectionId, preparedOptions);
    const topViewers = await this.fetchTopViewers(collectionId, preparedOptions);
    const topEditors = await this.fetchTopEditors(collectionId, preparedOptions);

    return {
      topDocuments,
      topViewers,
      topEditors,
    }
  }

  @action
  fetchTopDocuments = async (
    collectionId: string,
    options: {
      from: Date | string | number,
      to?: Date | string | number,
      limit?: number,
    }
  ): Promise<{
    actions: number;
    entity: DocumentModel;
  }[]> => {
    try {
      const res = await client.post("/collections.top_documents", {
        id: collectionId,
        from: options.from,
        to: options.to,
        limit: options.limit,
      });

      return res.data;
    } catch (e) {
      throw new Error("Error loading top documents");
    }
  }

  @action
  fetchTopViewers = async (
    collectionId: string,
    options: {
      from: Date | string | number,
      to?: Date | string | number,
      limit?: number,
    }
  ): Promise<{
    actions: number;
    entity: User;
  }[]> => {
    try {
      const res = await client.post("/collections.top_viewers", {
        id: collectionId,
        from: options.from,
        to: options.to,
        limit: options.limit,
      });

      return res.data;
    } catch (e) {
      throw new Error("Error loading top viewers");
    }
  }

  @action
  fetchTopEditors = async (
    collectionId: string,
    options: {
      from: Date | string | number,
      to?: Date | string | number,
      limit?: number,
    }
  ) => {
    try {
      const res = await client.post("/collections.top_editors", {
        id: collectionId,
        from: options.from,
        to: options.to,
        limit: options.limit,
      });

      return res.data;
    } catch (e) {
      throw new Error("Error loading top editors");
    }
  }

  async update(params: Record<string, any>): Promise<Collection> {
    const result = await super.update(params);

    // If we're changing sharing permissions on the collection then we need to
    // remove all locally cached policies for documents in the collection as they
    // are now invalid
    if (!params.sharing) {
      const collection = this.get(params.id);

      if (collection) {
        collection.documentIds.forEach((id) => {
          this.rootStore.policies.remove(id);
        });
      }
    }

    return result;
  }

  @override
  async fetch(
    id: string,
    options: Record<string, any> = {}
  ): Promise<Collection> {
    const item = this.get(id) || this.getByUrl(id);
    if (item && !options.force) {
      return item;
    }
    this.isFetching = true;

    try {
      const res = await client.post(`/collections.info`, {
        id,
      });
      invariant(res?.data, "Collection not available");
      this.addPolicies(res.policies);
      return this.add(res.data);
    } catch (err) {
      if (err instanceof AuthorizationError || err instanceof NotFoundError) {
        this.remove(id);
      }

      throw err;
    } finally {
      this.isFetching = false;
    }
  }

  @action.bound
  async fetchUsers(params: {
    id: string;
    query?: string;
    offset?: number;
    limit?: number;
  }): Promise<TCollectionUsersWithTotal> {
    this.isFetching = true;

    try {
      const res = await client.post(`/collections.users`, params);
      return {
        total: res.count,
        data: res.data,
      };
    } catch (e) {
      throw new Error("Error loading users");
    } finally {
      this.isFetching = false;
    }
  }

  @computed
  get publicCollections() {
    return this.orderedData.filter((collection) =>
      ["read", "read_write"].includes(collection.permission || "")
    );
  }

  star = async (collection: Collection) => {
    await this.rootStore.stars.create({
      collectionId: collection.id,
    });
  };

  unstar = async (collection: Collection) => {
    const star = this.rootStore.stars.orderedData.find(
      (star) => star.collectionId === collection.id
    );
    await star?.delete();
  };

  getPathForDocument(documentId: string): DocumentPath | undefined {
    return this.pathsToDocuments.find((path) => path.id === documentId);
  }

  async getByDocumentId(documentId: string): Promise<Collection | null> {
    let documentCollection: Collection | null = null;

    await this.fetchPage();

    this.data.forEach((collection) => {
      const isDocumentCollection = collection.documents.some((document) => document.id === documentId);


      if (isDocumentCollection) {
        documentCollection = collection;
      }
    })

    return documentCollection;
  }

  titleForDocument(documentUrl: string): string | undefined {
    const path = this.pathsToDocuments.find((path) => path.url === documentUrl);
    if (path) {
      return path.title;
    }

    return;
  }

  getByUrl(url: string): Collection | null | undefined {
    return find(this.orderedData, (col: Collection) => url.endsWith(col.urlId));
  }

  delete = async (collection: Collection) => {
    await super.delete(collection);
    this.rootStore.documents.fetchRecentlyUpdated();
    this.rootStore.documents.fetchRecentlyViewed();
  };

  // Дополнительные опции используются как параметры для экспорта в формат PDF
  // id - идентификатор коллекции
  // documentId - идентификатор документа
  // format - формат выгрузки: pdf - каждый документ в отдельном файле, singlePdf - все документы коллекции в одном файле
  export = (options?: {id: string, documentId?: string, format: "pdf" | "singlePdf" }) => {
    if (options) {
      return client.post("/collections.export_all", options);
    } else {
      return client.post("/collections.export_all");
    }
  };
}
