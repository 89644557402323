import React, { ComponentProps } from "react";
import Icon from "./Icon/Icon";

type IconProps = ComponentProps<typeof Icon>;

export function IcBaselineBookmarkBorder(props: IconProps) {
  return (
    <Icon {...props}>
      <path d="M17 3H7c-1.1 0-1.99.9-1.99 2L5 21l7-3l7 3V5c0-1.1-.9-2-2-2zm0 15l-5-2.18L7 18V5h10v13z"></path>
    </Icon>
  );
}

export default IcBaselineBookmarkBorder;
