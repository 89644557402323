import * as React from "react";
import Frame from "../components/Frame";
import { EmbedProps as Props } from ".";

const URL_REGEX = new RegExp(
  "https://([w.-]+.)?figma.com/(file|proto)/([0-9a-zA-Z]{22,128})(?:/.*)?$"
);

const EMBED_REGEX = new RegExp(
  "^(?:https:\\/\\/)?(?:www\\.)?figma\\.com\\/(embed)\\?embed_host=[a-z]+&url=(?:https:\\/\\/)?(?:www\\.)?figma\\.com\\/file\\/([0-9a-zA-Z]{22,128})"
)

export default class Figma extends React.Component<Props> {
  static ENABLED = [URL_REGEX, EMBED_REGEX];

  render() {
    return (
      <Frame
        {...this.props}
        src={`https://www.figma.com/embed?embed_host=yonote&url=${this.props.attrs.href}`}
        title="Figma Embed"
        border
      />
    );
  }
}
