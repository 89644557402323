import React, { ComponentProps } from "react";
import Icon from "./Icon/Icon";

type IconProps = ComponentProps<typeof Icon>;

export function SyncBlockIcon(props: IconProps) {
  return (
    <Icon>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_15_2)">
          <path d="M18.6279 10.4223L14.7779 8.65007V3.57229C14.7761 3.46576 14.7437 3.36198 14.6846 3.27333C14.6255 3.18468 14.5422 3.11489 14.4445 3.07229L10.2779 1.15562C10.2047 1.12178 10.1251 1.10425 10.0445 1.10425C9.96394 1.10425 9.88433 1.12178 9.81119 1.15562L5.64453 3.07229C5.54809 3.11692 5.46648 3.18827 5.40937 3.27787C5.35226 3.36748 5.32204 3.47159 5.3223 3.57785V8.65562L1.48897 10.4223C1.39254 10.4669 1.31093 10.5383 1.25381 10.6279C1.1967 10.7175 1.16649 10.8216 1.16675 10.9278V16.3612C1.16649 16.4674 1.1967 16.5715 1.25381 16.6612C1.31093 16.7508 1.39254 16.8221 1.48897 16.8667L5.65564 18.7834C5.72877 18.8172 5.80839 18.8348 5.88897 18.8348C5.96956 18.8348 6.04917 18.8172 6.12231 18.7834L10.0556 16.9723L13.989 18.7834C14.0621 18.8172 14.1417 18.8348 14.2223 18.8348C14.3029 18.8348 14.3825 18.8172 14.4556 18.7834L18.6223 16.8667C18.7187 16.8221 18.8003 16.7508 18.8575 16.6612C18.9146 16.5715 18.9448 16.4674 18.9445 16.3612V10.9278C18.9453 10.8222 18.9159 10.7185 18.8598 10.629C18.8037 10.5394 18.7233 10.4677 18.6279 10.4223ZM14.2279 12.2223L11.389 10.9278L14.2279 9.62229L17.0667 10.9278L14.2279 12.2223ZM13.6723 8.64451L10.0945 10.3112V6.03896C10.1615 6.03599 10.2274 6.02094 10.289 5.99451L13.6667 4.44451V8.65562L13.6723 8.64451ZM10.0556 2.26673L12.8945 3.57229L10.0556 4.87785L7.22231 3.57229L10.0556 2.26673ZM5.88897 9.61673L8.72786 10.9223L5.88897 12.2223L3.05008 10.9278L5.88897 9.61673ZM9.50008 16.0001L5.88897 17.6667V13.3945C5.9665 13.3899 6.04221 13.3691 6.11119 13.3334L9.48897 11.7778L9.50008 16.0001ZM17.8334 16.0001L14.2445 17.6667V13.3945C14.3145 13.387 14.3823 13.3662 14.4445 13.3334L17.8223 11.7778L17.8334 16.0001Z" fill={props.color}/>
        </g>
        <defs>
          <clipPath id="clip0_15_2">
            <rect width="20" height="20" fill="white"/>
          </clipPath>
        </defs>
      </svg>
    </Icon>
  );
}

export default SyncBlockIcon;
