import React, { ComponentProps } from "react";
import Icon from "./Icon/Icon";

type IconProps = ComponentProps<typeof Icon>;

export function IcBaselineArrowForward(props: IconProps) {
  return (
    <Icon {...props}>
      <path d="m12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z"></path>
    </Icon>
  );
}

export default IcBaselineArrowForward;
