import { action, makeObservable } from "mobx";
import BaseStore from "~/stores/BaseStore";
import RootStore from "~/stores/RootStore";
import Collection from "~/models/Collection";
import Document from "~/models/Document";
import Team from "~/models/Team";
import User from "~/models/User";
import { prepareOptionsToRequest } from "~/components/Analytics/utils";
import { client } from "~/utils/ApiClient";

export type AnalyticsBase = {
  entity: Document | Collection | User;
  actions: number;
}

export interface AnalyticsCollection extends AnalyticsBase {
  entity: Collection;
  actions: number;
}

export interface AnalyticsDocument extends AnalyticsBase {
  entity: Document;
  actions: number;
}

export interface AnalyticsViewers extends AnalyticsBase {
  entity: User;
  actions: number;
}

export interface AnalyticsEditors extends AnalyticsBase {
  entity: User;
  actions: number;
}

export type AnalyticsActiveUsers = {
  activeMembers: number;
  activeViewers: number;
  previousActiveMembers: number;
  previousActiveViewers: number;
}

export type AnalyticsTotalActions = {
  totalViews: number;
  uniqueViews: number;
  creations: number;
  edits: number;
}

export type AnalyticsActiveUsersChart = {
  timestamp: string;
  total: number;
}

export type AnalyticsSearchStats = {
  query: string;
  searches: number;
  uniqueSearches: number;
}

export type AnalyticsDocumentsStats = Document & {
  collection: Collection;
  views: number;
  uniqueViews: number;
}

type RequestOptions = {
  from: Date | number | string;
  to?: Date | number | string;
  limit?: number;
}

export type TeamInfoResponse = {
  data: {
    avatarUrl: string;
  }
}

export default class TeamStore extends BaseStore<Team> {
  constructor(rootStore: RootStore) {
    super(rootStore, Team);
    makeObservable(this);
  }

  @action
  fetchCollectionsAnalytics = async (options: RequestOptions): Promise<AnalyticsCollection[]> => {
    try {
      const res = await client.post("/team.top_collections", prepareOptionsToRequest(options));

      return res.data;
    } catch (e) {
      throw new Error("Error loading top collections");
    }
  }

  @action
  fetchDocumentsAnalytics = async (options: RequestOptions): Promise<AnalyticsDocument[]> => {
    try {
      const res = await client.post("/team.top_documents", prepareOptionsToRequest(options));
      return res.data;
    } catch (e) {
      throw new Error("Error loading top documents");
    }
  }

  @action
  fetchViewersAnalytics = async (options: RequestOptions): Promise<AnalyticsViewers[]> => {
    try {
      const res = await client.post("/team.top_viewers", prepareOptionsToRequest(options));
      return res.data;
    } catch (e) {
      throw new Error("Error loading top viewers");
    }
  }

  @action
  fetchEditorsAnalytics = async (options: RequestOptions): Promise<AnalyticsEditors[]> => {
    try {
      const res = await client.post("/team.top_editors", prepareOptionsToRequest(options));
      return res.data;
    } catch (e) {
      throw new Error("Error loading top editors");
    }
  }

  @action
  fetchActiveUsersAnalytics = async (options: RequestOptions): Promise<AnalyticsActiveUsers | undefined> => {
    try {
      const res = await client.post("/team.active_users", prepareOptionsToRequest(options));
      return res.data;
    } catch (e) {
      throw new Error("Error loading active users");
    }
  }

  @action
  fetchTotalActionsAnalytics = async (options: RequestOptions): Promise<AnalyticsTotalActions | undefined> => {
    try {
      const res = await client.post("/team.total_actions", prepareOptionsToRequest(options));
      return res.data;
    } catch (e) {
      throw new Error("Error loading total actions");
    }
  }

  @action
  fetchActiveUsersChartAnalytics = async (options: RequestOptions): Promise<AnalyticsActiveUsersChart[]> => {
    try {
      const res = await client.post("/team.active_users_chart", prepareOptionsToRequest(options));
      return res.data;
    } catch (e) {
      throw new Error("Error loading chart");
    }
  }

  @action
  fetchSearchStatsAnalytics = async (options: RequestOptions): Promise<AnalyticsSearchStats[]> => {
    try {
      const res = await client.post("/team.search_stats", prepareOptionsToRequest(options));
      return res.data;
    } catch (e) {
      throw new Error("Error loading search statistic");
    }
  }

  @action
  fetchDocumentsStatsAnalytics = async (options: RequestOptions): Promise<AnalyticsDocumentsStats[]> => {
    try {
      const res = await client.post("/team.documents_stats", prepareOptionsToRequest(options));
      return res.data;
    } catch (e) {
      throw new Error("Error loading documents statistics");
    }
  }

  @action
  fetchTeamInfo = async (subdomain: string) => {
    try {
      const res: TeamInfoResponse = await client.post("/team.info", { subdomain });
      return res.data;
    } catch (e) {
      throw new Error("Error loading team info");
    }
  }
}
