import React, { ReactElement, PropsWithChildren, CSSProperties } from "react";
import CenteredModal from "@uikit/Modal/CenteredModal/CenteredModal";
import FullscreenModal from "@uikit/Modal/FullscreenModal/FullscreenModal";
import Portal from "@uikit/Modal/Portal";

export enum ModalMode {
  Fullscreen = "fullscreen",
  Centered = "centered"
}

type Props = {
  /** Флаг, указывающий, открыто ли модальное окно. */
  isOpen: boolean;
  /** Функция, которая выполняется для закрытия модального окна. */
  onRequestClose: () => void;
  /** Строка с необязательным заголовком. */
  title?: ReactElement | string;
  /** Режим отображения: по центру или же на весь экран (по-умолчанию) */
  mode?: ModalMode;
  /** Флаг, указывающий, нужно ли показывать кнопку закрытия модального окна */
  showCloseButton?: boolean;
  /** Флаг, указывающий, должно ли модальное окно закрываться при нажатии на кнопку Esc */
  closeForEscape?: boolean;
  /** Флаг, указывающий, должно ли модальное окно закрываться при клике вне его */
  closeForOutsideClick?: boolean;
  /** Дополнительные правила стилей, которые будут применены к контейнеру модального окна */
  style?: CSSProperties;
  className?: string;
}

const Modal = ({
  isOpen,
  onRequestClose,
  children,
  title = "",
  mode = ModalMode.Fullscreen,
  style = {},
  showCloseButton = true,
  closeForEscape = true,
  closeForOutsideClick = true,
  className = "",
}: PropsWithChildren<Props>) => {
  if (!isOpen) {
    return null;
  }

  return (
    <Portal
      onRequestClose={onRequestClose}
      closeForEscape={closeForEscape}
      closeForOutsideClick={closeForOutsideClick}
    >
      {mode === ModalMode.Centered && (
        <CenteredModal
          title={title}
          onRequestClose={onRequestClose}
          showCloseButton={showCloseButton}
          style={style}
          className={className}
        >
          {children}
        </CenteredModal>
      )}
      {mode === ModalMode.Fullscreen && (
        <FullscreenModal
          title={title}
          onRequestClose={onRequestClose}
          showCloseButton={showCloseButton}
          style={style}
        >
          {children}
        </FullscreenModal>
      )}
    </Portal>
  );
}

export default Modal;
