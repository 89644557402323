import React, { ComponentProps } from "react";
import Icon from "./Icon/Icon";

type IconProps = ComponentProps<typeof Icon>;

export function CiHeadingH2(props: IconProps) {
  return (
    <Icon size={18} {...props}>
      <path d="M10 4v7H4V4H2v16h2v-7h6v7h2V4h-2Zm12 7.75C22 9.679 20.21 8 18 8s-4 1.679-4 3.75h2.133l.007-.144C16.218 10.707 17.02 10 18 10c1.03 0 1.867.784 1.867 1.75c0 .439-.173.841-.459 1.148L14 18.444V20h8v-2h-4.497l3.516-3.79l.158-.18A3.59 3.59 0 0 0 22 11.75Z"></path>
    </Icon>
  );
}

export default CiHeadingH2;
