import { DocumentType } from "@shared/types";
import parseDocumentSlug from "@shared/utils/parseDocumentSlug";
import DocumentsStore from "~/stores/DocumentsStore";
import env from "~/env";

// TODO: нужен ресерч почему у нового документа может быть не пустой текст
//  после выпилить эту функцию
export function canBeConvertedToJSON(str: string) {
  try {
    return (JSON.parse(str) && Boolean(str));
  } catch (e) {
    return false;
  }
}

export async function getDocumentByUrl(url: string, documentsStore: DocumentsStore) {
  let document = await getDocumentBySlug(url, documentsStore);

  if (!document) {
    document = await getDocumentByShareId(url, documentsStore);
  }

  return document;
}

export async function isYonoteValidUrl(url: string, documentsStore: DocumentsStore) {
  const document = await getDocumentByUrl(url, documentsStore);

  if (!document) {
    return false;
  }

  return document.type !== DocumentType.Embed;
}

export function getUrlFromIframe(value: string) {
  const matches = value.match(/<iframe[^>]*src=[\"|']([^'\"]+)[\"|'][^>]*>/i);
  if (!matches) {
    return null;
  }

  return matches[1];
}

export async function getDocumentByShareId(url: string, documentsStore: DocumentsStore) {
  const shareId = getShareIdFromUrl(url);

  if (!shareId) {
    return undefined;
  }

  try {
    return documentsStore.fetch("", {
      shareId,
    });
  } catch (e) {
    return undefined;
  }
}

async function getDocumentBySlug(url: string, documentsStore: DocumentsStore) {
  const slug = parseDocumentSlug(url) as string;

  if (!slug) {
    return undefined;
  }

  const urlParts = slug.split("-");
  const urlId = urlParts[urlParts.length - 1].split("/")[0];

  if (urlId.length < 10) {
    return undefined;
  }

  try {
    const documentWithTree = await documentsStore.fetchWithSharedTree(urlId);
    return documentWithTree.document;
  } catch (e) {
    return undefined;
  }
}

export const isYonoteLink = (link: string) => {
  return link.includes(env.BASENAME_FOR_SUBDOMAIN);
}

export function getShareIdFromUrl(url: string) {
  const { pathname } = new URL(url);

  if (!pathname.startsWith("/share/")) {
    return undefined;
  }

  return pathname.split("/")[2];
}