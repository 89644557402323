import React, {
  ReactNode,
  FC,
  KeyboardEvent,
  useCallback,
  useState,
  memo,
  useLayoutEffect,
  useRef,
  useEffect,
} from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import breakpoint from "styled-components-breakpoint";
import Fade from "~/components/Fade";
import Flex from "~/components/Flex";

export type TCenteredTitle = {
  isScrolled?: boolean;
  title?: ReactNode;
  titleString?: string;
  handleScroll?: () => void;
  onSaveTitle?: (value: string) => void;
  alwaysShow?: boolean;
  readOnly?: boolean;
}

const CenteredTitle: FC<TCenteredTitle> = ({
  isScrolled,
  handleScroll,
  alwaysShow,
  title,
  titleString,
  onSaveTitle,
  readOnly,
}) => {
  const { t } = useTranslation();

  const [value, setValue] = useState(titleString);
  const [isEditing, setIsEditing] = useState(!titleString);

  const isFirstRender = useRef(true);
  const inputRef = useRef<HTMLInputElement | null>(null);

  useLayoutEffect(() => {
    setTimeout(() => {
      if (isEditing) {
        inputRef.current?.focus();
      }
    })
  }, [isEditing]);

  useEffect(() => {
    if (value !== titleString) {
      setValue(titleString);
    }
  }, [titleString]);

  const onChange = useCallback((newTitle: string) => {
    setValue(newTitle);

    if (onSaveTitle && newTitle !== title) {
      onSaveTitle(newTitle || "");
    }
  }, [onSaveTitle, title]);

  const onBlur = useCallback(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    setIsEditing(false);
  }, []);

  const onKeyDown = useCallback((e: KeyboardEvent<HTMLInputElement>) => {
    if (e.code === "Enter") {
      onBlur();
    }
  }, [onBlur]);

  if (isScrolled) {
    return (
      <Title onClick={handleScroll}>
        <Fade>{title}</Fade>
      </Title>
    );
  }

  if (alwaysShow) {
    return (
      <TitleWrapper align="center" justify="center">
        {isEditing ? (
          <StyledInput
            ref={inputRef}
            value={value}
            onChange={e => onChange(e.target.value)}
            onKeyDown={onKeyDown}
            onBlur={onBlur}
            placeholder={t("Untitled")}
            autoFocus
          />
        ) : (
          <Title
            onClick={() => !readOnly && setIsEditing(true)}
            title={value || t("Untitled")}
            fullWidth
          >
            {value || t("Untitled")}
          </Title>
        )}
      </TitleWrapper>
    );
  }

  return <div></div>
}

const TitleWrapper = styled(Flex)`
  width: 70%;
`;

const Title = styled.div<{ fullWidth?: boolean; }>`
  width: ${({ fullWidth }) => fullWidth ? "100%" : "fit-content"};
  text-align: center;
  display: none;
  font-size: 16px;
  font-weight: 600;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
  min-width: 0;

  ${breakpoint("tablet")`
    padding-left: 0;
    display: block;
  `};

  svg {
    vertical-align: bottom;
  }

  @media (display-mode: standalone) {
    overflow: hidden;
    flex-grow: 0 !important;
  }
`;

const StyledInput = styled.input`
  width: 100%;
  text-align: center;
  outline: none;
  background: none;
  border: none;
  font-size: 16px;
  font-weight: 600;
`;

export default memo(CenteredTitle);
