import { SignatureAlgorithm } from "passport-saml/lib/node-saml/types";
import { ConnectionSecurity } from './common';

export interface LdapSettings {
  hostName: string;
  port: string;
  ssl: boolean;
  user: string;
  filter: string;
  base: string;
  customButtonName: string;
  connectionSecurity: ConnectionSecurity;
  userNameAttribute: string;
  emailAttribute: string;
  firstNameAttribute?: string;
  lastNameAttribute?: string;
  password?: string;
  certificate?: string;
  passLength?: number;
}

export interface SamlFilter {
  attributeName: string;
  attributeValue: string;
  isAllowed: boolean;
  condition: string;
  isEqual?: boolean;
  isIn?: boolean;
  isInArray?: boolean;
}

export enum DigestAlgorithm {
  Sha1 = "sha1",
  Sha256 = "sha256",
  Sha512 = "sha512",
}

export interface SamlSettings {
  customButtonName: string;
  entryPoint: string;
  digestAlgorithm: DigestAlgorithm;
  callbackUrl: string;
  passReqToCallback: boolean;
  emailAttribute: string;
  firstName: string;
  lastName: string;
  signatureAlgorithm?: SignatureAlgorithm;
  cert: string;
  filters?: SamlFilter[];
}

export interface CustomOidcSettings {
  clientId: string;
  clientSecret: string;
  registrationEndpoint: string;
  authorizationEndpoint: string;
  tokenEndpoint: string;
  userInfoEndpoint: string;
  endSessionEndpoint: string;
  customButtonName: string;
}
