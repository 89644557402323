import { action, makeObservable, observable } from "mobx";

export default class LicenseStore {
  @observable
  expired = false;

  constructor() {
    makeObservable(this);
  }

  @action.bound
  setExpired() {
    this.expired = true;
  }

  @action.bound
  clear() {
    this.expired = false;
  }
}
