import { action, makeObservable } from "mobx";
import BaseModel from "./BaseModel";
import User from "./User";

class View extends BaseModel {
  constructor(fields: any, store: any) {
    super(fields, store);
    Object.assign(this, fields);
    makeObservable(this);
  }

  id: string;

  documentId: string;

  firstViewedAt: string;

  lastViewedAt: string;

  count: number;

  user: User;

  @action
  touch() {
    this.lastViewedAt = new Date().toString();
  }
}

export default View;
