import i18next from "i18next";
import { ConnectionSecurity, LdapSettings, ProviderType } from "@shared/types";
import BaseProviderStore, { CommonData, ProviderActions, ResponseNotify } from "~/stores/BaseProviderStore";
import { client } from "~/utils/ApiClient";

export enum SecurityOptionType {
  NONE = "None",
  SSL = "SSL",
  TLS = "TLS",
  STARTTLS = "STARTTLS",
}

export type LdapData = LdapSettings & CommonData;

export default class ProviderLDAPStore extends BaseProviderStore {
  ENDPOINT = ProviderType.LDAP;
  DEFAULT_BUTTON_NAME = "Login with AD/LDAP";
  data: LdapData;

  constructor() {
    super();
  }

  notify({ connection, adminAuth, userCount }: any): ResponseNotify {
    const res = {
      text: "",
      type: "success",
      value: true,
    };

    if (adminAuth && connection) {
      if (userCount > 0) {
        res.text = i18next.t("LDAP users were found", { count: userCount });
        return res;
      }

      if (userCount === 0) {
        res.text = i18next.t("No users found. Please, double-check your filter and attribute settings.");
        return res;
      }

      return {
        ...res,
        text: i18next.t("{{ userCount }} LDAP users were found. Please, double-check your attributes and filter settings", { userCount }),
      };
    }

    if (!connection) {
      res.text = i18next.t("Connection error, please, double-check directory names");
      res.type = "error";
    }

    if (!adminAuth) {
      res.text = i18next.t("Admin check failed. Please, double-check your credentials");
      res.type = "error";
    }

    res.value = false;
    return res;
  }

  handleResponse(response: any, action: ProviderActions) {
    if ([ProviderActions.CREATE, ProviderActions.UPDATE].includes(action) && response.data.provider.id) {
      return {
        text: i18next.t("Saved successfully!"),
        type: "success",
        value: true,
      };
    }

    return this.notify(response.result);
  }

  responseMap(response: any, action: ProviderActions): ResponseNotify {
    if (!response) {
      return {
        type: "error",
        userCount: null,
      };
    }

    if (action === ProviderActions.UPDATE) {
      if (response.ok) {
        return {
          type: "success",
          text: i18next.t("Saved successfully!"),
        }
      }

      return {
        type: "error",
        text: i18next.t("Failed to save provider"),
      };
    }

    /** на случай если пользователь захочет перезаписать данные сразу после создания */
    this.data.id = response.data?.id || this.data.id;
    const success = this.handleResponse(response, action);
    return {
      ...success,
      userCount: response.result?.userCount || null,
    };
  }

  getFormDataKeyValue(key: string, data: LdapData, action: ProviderActions) {
    switch (key) {
      case "providerId":
        /* update expects "id" and ping expects "providerId" **/
        return { formKey: action === ProviderActions.PING ? "providerId" : "id", formValue: data[key] };
      case "ssl":
        return { formKey: key, formValue: JSON.stringify(data[key]) };
      case "customButtonName":
        return {
          formKey: key,
          formValue: data[key]?.trim() ? data[key] : i18next.t(this.DEFAULT_BUTTON_NAME),
        };
      case "certificate":
        return { formKey: key, formValue: data[key] ? data[key] : null };
      case "passLength":
        return { formKey: key, formValue: null };
      case "isActive":
        return { formKey: "isActive", formValue: String(data[key]) };
      case "isDefault":
        return { formKey: "isDefault", formValue: String(data[key]) };
      case "starttls-certificate":
      case "tls-certificate": {
        const value = data[key];
        return {
          formKey: "certificate",
          formValue: typeof value === "string"
            ? new Blob([value], { type : 'plain/text' })
            : value?.fileList[0].originFileObj,
        };
      }
      default:
        return { formKey: key, formValue: data[key] };
    }
  }

  getFormData(action: ProviderActions) {
    const formData = new FormData();
    this.data.ssl = this.data.connectionSecurity === ConnectionSecurity.SSL;

    for (const key in this.data) {
      const { formKey, formValue } = this.getFormDataKeyValue(key, this.data, action);

      if (formValue) {
        formData.append(formKey, formValue);
      }
    }

    return formData;
  }

  async pingProvider() {
    try {
      const res = await client.post(`/${this.ENDPOINT}.ping`, this.getFormData(ProviderActions.PING));
      return this.responseMap(res, ProviderActions.PING);
    } catch (e) {
      throw new Error("Check the correctness of the entered data");
    }
  }

  async request() {
    switch (this.action) {
      case ProviderActions.CREATE:
        return this.createProvider();
      case ProviderActions.UPDATE:
        return this.updateProvider();
      case ProviderActions.PING:
        return this.pingProvider();
      default:
        return undefined;
    }
  }
}