import React, { ComponentProps } from "react";
import Icon from "./Icon/Icon";

type IconProps = ComponentProps<typeof Icon>;

export function CiHeadingH3(props: IconProps) {
  return (
    <Icon size={18} {...props}>
      <path d="M10 4v7H4V4H2v16h2v-7h6v7h2V4h-2Zm11.729 6l.002-2H14.5v2h4.378l-3.176 3.283l1.407 1.515c.256-.118.546-.186.854-.186c1.04 0 1.884.768 1.884 1.714c0 .947-.844 1.715-1.884 1.715c-.917 0-1.681-.597-1.849-1.386L14 17.029C14.36 18.722 15.998 20 17.963 20C20.193 20 22 18.355 22 16.326c0-1.691-1.256-3.117-2.968-3.543L21.73 10Z"></path>
    </Icon>
  );
}

export default CiHeadingH3;
