import { action, makeObservable, observable } from "mobx";

export default class DownloadListStore {
  @observable
  ids: string[] = [];

  constructor() {
    makeObservable(this);
  }

  @action
  addOperationId = (id: string) => {
    this.ids.push(id);
  }

  @action
  removeOperationId = (id: string) => {
    this.ids = this.ids.filter((value) => value !== id);
  }
}
