import { DefaultTheme } from "styled-components";
import { depths } from "@shared/styles";

const getContainerCSSText = (theme: DefaultTheme, vh: number) => {
  const rules = {
    position: "fixed",
    width: "100vw",
    height: `${vh * 100}px`,
    top: "0",
    left: "0",
    "box-sizing": "border-box",
    "background-color": theme.modalBackdrop,
    display: "flex",
    "justify-content": "center",
    "align-items": "center",
    "z-index": `${depths.modalOverlay}`,
  };

  return Object
    .entries(rules)
    .map(([key, value]) => `${key}: ${value};`)
    .join(" ");
};

export default getContainerCSSText;
