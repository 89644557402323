import { action, makeObservable, observable } from "mobx";
import BaseStore from "~/stores/BaseStore";
import RootStore from "~/stores/RootStore";
import Provider from "~/models/Provider";
import { client } from "~/utils/ApiClient";

export default class ProvidersStore extends BaseStore<Provider> {
  @observable
  providersList: Provider[] = [];

  constructor(rootStore: RootStore) {
    super(rootStore, Provider);
    makeObservable(this);
  }

  @action
  async fetchProviders(): Promise<Provider[]> {
    try {
      const res = await client.post("/providers.list");
      const providers = res.data.providers;
      this.providersList = providers;
      return providers;
    } catch (e) {
      throw new Error("Error loading providers list");
    }
  }

  @action
  async getInfo(providerId: string): Promise<Provider | undefined> {
    try {
      const res = await client.post("/providers.info", { id: providerId });
      return res.data.provider;
    } catch (e) {
      throw new Error("Error loading provider info");
    }
  }

  @action
  async deleteProvider(providerId: string) {
    try {
      const res = await client.post("/providers.delete", { id: providerId });
      const resProviderId = res.providerId;

      this.providersList = this.providersList.filter(provider => provider.id !== resProviderId);
      return resProviderId;
    } catch (e) {
      throw new Error("Error deleting provider");
    }
  }
}