import React, { ComponentProps } from "react";
import Icon from "./Icon/Icon";

type IconProps = ComponentProps<typeof Icon>;

export function TablerMathFunction(props: IconProps) {
  return (
    <Icon {...props}>
      <path
        fill="none"
        stroke={props.color || "#333"}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M3 19a2 2 0 0 0 2 2c2 0 2-4 3-9s1-9 3-9a2 2 0 0 1 2 2m-8 7h6m4 0l6 6m-6 0l6-6"
      ></path>
    </Icon>
  );
}

export default TablerMathFunction;
