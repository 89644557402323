import { makeObservable, observable, override } from "mobx";
import { TDateValue } from "~/components/DatePicker/types";
import BaseModel from "./BaseModel";
import User from "./User";
import Field from "./decorators/Field";

class Share extends BaseModel {
  constructor(fields: any, store: any) {
    super(fields, store);
    Object.assign(this, fields);
    makeObservable(this)
  }

  @Field
  @override
  id: string;

  @Field
  @observable
  published: boolean;

  @Field
  @observable
  includeChildDocuments: boolean;

  @Field
  @observable
  documentId: string;

  @Field
  @observable
  allowCopy: boolean;

  documentTitle: string;

  documentUrl: string;

  lastAccessedAt: string | null | undefined;

  url: string;

  createdBy: User;

  link: string;

  exposesAt: TDateValue;

  expiresAt: TDateValue;
}

export default Share;
