import i18n from "i18next";
import { action, computed, makeObservable, observable } from "mobx";
import { EmbedDescriptor } from "@shared/editor/types";
import Document from "~/models/Document";
import { embedsWithOtherService } from "~/components/Embed/constants";
import { canBeConvertedToJSON } from "~/components/Embed/utils";

export type EmbedData = EmbedDescriptor & {
  url: string;
  value: string;
  canBeRendered?: boolean;
}

export default class EmbedStore {
  @observable
  isDrawerOpen = false;

  @observable
  private document?: Document;

  constructor() {
    makeObservable(this);
  }

  @computed
  get data(): EmbedData | undefined {
    if (!this.document || !(this.document.text && canBeConvertedToJSON(this.document.text))) {
      return undefined;
    }

    const embedInfo = JSON.parse(this.document.text);

    return {
      ...this.getByType(embedInfo.type),
      url: embedInfo.url,
      value: embedInfo.value,
      canBeRendered: embedInfo.canBeRendered,
    }
  }

  @action.bound
  setDocument(document?: Document) {
    this.document = document;
  }

  save(type: string, url: string, value: string) {
    if (!this.document) {
      throw new Error("Document required");
    }

    return this.document.store.update({
      id: this.document.id,
      text: JSON.stringify({
        type,
        url,
        value,
      })
    });
  }

  getByType(type: string) {
    return embedsWithOtherService.find(embed => embed.type === type) as EmbedDescriptor;
  }

  getDrawerTitle(embed: EmbedDescriptor | EmbedData) {
    if (embed.type === "otherService") {
      return i18n.t("Embed");
    }

    return i18n.t("Embed a {{ type }}", {
      type: i18n.t(embed.title),
    })
  }

  @action.bound
  setIsDrawerOpen(state: boolean) {
    this.isDrawerOpen = state;
  }

  clear() {
    this.setDocument(undefined);
    this.setIsDrawerOpen(false);
  }
}
