import * as React from "react";
import Frame from "../components/Frame";
import { EmbedProps as Props } from ".";

const URL_REGEX = /(?:https?:\/\/)?(?:www\.)?youtu\.?be(?:\.com)?\/?.*(?:watch|embed)?(?:.*v=|v\/|\/)([a-zA-Z0-9_-]{11})$/i;

const URL_REGEX_WITH_TIME = [
  /https:\/\/youtu\.be\/([a-zA-Z0-9_-]{11})\?t=(\d+)$/i,
  /https:\/\/www\.youtube\.com\/watch\?v=([a-zA-Z0-9_-]{11})&t=(\d+)s/i,
  // https://gist.github.com/brunodles/927fd8feaaccdbb9d02b
  /(?:https?:\/\/)?(?:www\.)?youtu\.?be(?:\.com)?\/?.*(?:watch|embed)?(?:.*v=|v\/|\/)([\w\-_]+)?/i,
];

export default class YouTube extends React.Component<Props> {
  static ENABLED = [URL_REGEX, ...URL_REGEX_WITH_TIME];

  render() {
    const { matches } = this.props.attrs;
    const videoId = matches[1];
    const time = matches[2];

    return (
      <Frame
        {...this.props}
        src={`https://www.youtube.com/embed/${videoId}?modestbranding=1${time ? "&start=" + time : ""}`}
        title={`YouTube (${videoId})`}
      />
    );
  }
}
