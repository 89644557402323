import React, { forwardRef, memo, MouseEvent, useCallback } from "react";
import styled from "styled-components";
import Spinner from "../../components/Spinner";
import { modeStyle, sizeStyle } from "./helpers";
import { ComputedModeStyle, ComputedSizeStyle, ButtonProps } from "./types";
import { TJustify } from "@uikit/common/types";

const Button = forwardRef<HTMLButtonElement | null, ButtonProps>(function UIButton ({
  icon,
  onClick,
  justify= "center",
  mode = "primary",
  size = "middle",
  loading = false,
  disabled = false,
  rounded = false,
  fullwidth = false,
  children = null,
  ...rest
}, buttonRef) {
  const iconOnly = (icon || loading) && !children;
  const computedModeStyles = modeStyle(rest.danger);
  const computedSizeStyles = sizeStyle(Boolean(iconOnly) && !children);

  const iconNode = loading ? <Spinner /> : icon;

  const handleClick = useCallback((e: MouseEvent<HTMLButtonElement>) => {
    if (loading || disabled) {
      e.preventDefault();
      return;
    }
    onClick?.(e);
  }, [loading, disabled, onClick]);

  return (
    <StyledButton
      {...rest}
      ref={buttonRef}
      mode={mode}
      size={size}
      rounded={rounded}
      disabled={disabled}
      fullwidth={fullwidth}
      onClick={handleClick}
      $justify={justify}
      $computedModeStyles={computedModeStyles}
      $computedSizeStyles={computedSizeStyles}
      className={iconOnly ? `${rest.className} icon-only` : rest.className}
    >
      {iconNode}
      {children}
    </StyledButton>
  )
});

const StyledButton = styled.button<Partial<ButtonProps> & {
  $computedModeStyles: ComputedModeStyle;
  $computedSizeStyles: ComputedSizeStyle;
  $justify: TJustify;
}>`
  display: inline-flex;
  align-items: center;
  justify-content: ${({$justify}) => $justify};
  box-sizing: border-box;
  border-width: 1px;
  outline: none;
  cursor: pointer;
  font-weight: 500;
  transition: 0.3s;
  margin: 0;
  white-space: nowrap;
  
  padding: ${({$computedSizeStyles, size}) => $computedSizeStyles[size!].padding};
  background: ${({$computedModeStyles, mode, theme}) => theme[$computedModeStyles[mode!].backgroundColor]};
  color: ${({$computedModeStyles, mode, theme}) => theme[$computedModeStyles[mode!].color]};
  box-shadow: ${({$computedModeStyles, mode, theme}) => {
    const shadow = $computedModeStyles[mode!].boxShadow;
    return shadow === "none" ? "none" : theme[shadow];
  }};
  font-size: ${({$computedSizeStyles, size}) => $computedSizeStyles[size!].fontSize};
  
  border-color: ${({$computedModeStyles, mode, theme}) => theme[$computedModeStyles[mode!].borderColor]};
  border-style: ${({$computedModeStyles, mode}) => $computedModeStyles[mode!].borderStyle || "solid"};
  border-radius: ${({rounded, theme}) => rounded ? "50%" : theme.defaultBorderRadius};
  
  height: ${({$computedSizeStyles, size}) => $computedSizeStyles[size!].height};
  width: ${({$computedSizeStyles, size, fullwidth}) => fullwidth ? "100%" : $computedSizeStyles[size!].width};
  
  &:hover {
    &:not(:disabled) {
      background: ${({$computedModeStyles, mode, theme}) => theme[$computedModeStyles[mode!].hoverBackground]};
      color: ${({$computedModeStyles, mode, theme}) => theme[$computedModeStyles[mode!].hoverColor]};
      border-color: ${({$computedModeStyles, mode, theme}) => theme[$computedModeStyles[mode!].hoverBorderColor]};
    }
  }
  
  &:disabled {
    color: ${({ theme }) => theme.buttonDisabledColor};
    border-color: transparent;
    cursor: not-allowed;
    
    &:not([mode="link"], [mode="flat"]) {
      background: ${({ theme }) => theme.buttonDisabledBackground};
    }
  }
  
  &.icon-only {
    svg {
      margin-right: 0 !important;
    }
  }
  
  svg {
    fill: currentColor;
    width: ${({$computedSizeStyles, size}) => $computedSizeStyles[size!].svgSize};
    height: ${({$computedSizeStyles, size}) => $computedSizeStyles[size!].svgSize};
    margin: 0 4px 0 0;

    circle {
      stroke: currentColor;
    }
  }
`;

export default memo(Button);
