import { makeObservable } from "mobx";
import BaseModel from "./BaseModel";
import User from "./User";

class GroupMembership extends BaseModel {
  constructor(fields: any, store: any) {
    super(fields, store);
    Object.assign(this, fields);
    makeObservable(this);
  }

  id: string;

  userId: string;

  groupId: string;

  user: User;
}

export default GroupMembership;
