import { makeObservable } from "mobx";
import BaseModel from "./BaseModel";
import User from "./User";

class Revision extends BaseModel {
  constructor(fields: any, store: any) {
    super(fields, store);
    Object.assign(this, fields);
    makeObservable(this);
  }

  id: string;

  documentId: string;

  title: string;

  icon: string;

  text: string;

  createdAt: string;

  createdBy: User;
}

export default Revision;
