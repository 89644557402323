import { observable, override } from "mobx";
import BaseModel from "./BaseModel";

class Policy extends BaseModel {
  constructor(fields: any, store: any) {
    super(fields, store);
    Object.assign(this, fields);
  }

  @override
  id: string;

  @observable
  abilities: Record<string, boolean>;
}

export default Policy;
