// Хук для получения shareId предназначенный для использования в тех случаях,
// когда useParams возвращает некорректное значение идентификатора
const useShareId = (): string | undefined => {
  let shareId: string | undefined;
  if (window && window.location.pathname.startsWith("/share/")) {
    shareId = window.location.pathname.split("/")[2];
  }
  return shareId;
}

export default useShareId;