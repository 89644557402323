import React, { ComponentProps } from "react";
import Icon from "./Icon/Icon";

type IconProps = ComponentProps<typeof Icon>;

export function IcBaselineDownload(props: IconProps) {
  return (
    <Icon {...props}>
      <path d="M5 20h14v-2H5v2zM19 9h-4V3H9v6H5l7 7l7-7z"></path>
    </Icon>
  );
}

export default IcBaselineDownload;
