import { CSSProperties } from "react";
import styled from "styled-components";

export type JustifyValues = CSSProperties["justifyContent"];

export type AlignValues = CSSProperties["alignItems"];

export type FlexProps = {
  auto?: boolean;
  column?: boolean;
  align?: AlignValues;
  justify?: JustifyValues;
  shrink?: boolean;
  reverse?: boolean;
  gap?: number;
  wrap?: "nowrap" | "wrap" | "wrap-reverse";
  alignSelf?: "center" | "start" | "end" | "self-star" | "self-end" | "flex-start" | "flex-end" | "auto" | "stretch";
}

const Flex = styled.div<FlexProps>`
  display: flex;
  flex: ${({ auto }) => (auto ? "1 1 auto" : "initial")};
  flex-direction: ${({ column, reverse }) =>
    reverse
      ? column
        ? "column-reverse"
        : "row-reverse"
      : column
      ? "column"
      : "row"};
  align-items: ${({ align }) => align};
  justify-content: ${({ justify }) => justify};
  flex-shrink: ${({ shrink }) => (shrink ? 1 : "initial")};
  gap: ${({ gap }) => (gap ? `${gap}px` : "initial")};
  min-height: 0;
  min-width: 0;
  flex-wrap: ${({wrap}) => wrap || "nowrap"};
  align-self: ${({alignSelf}) => alignSelf || "auto"};
`;

export default Flex;
