import React, { ComponentProps } from "react";
import Icon from "./Icon/Icon";

type IconProps = ComponentProps<typeof Icon>;

export function MingcuteBook5Line(props: IconProps) {
  return (
    <Icon {...props}>
      <g fill="currentColor" fillRule="evenodd">
        <path
          fill={props.color}
          d="M3 4a2 2 0 0 1 2-2h2c.364 0 .706.097 1 .268A1.99 1.99 0 0 1 9 2h2c.727 0 1.364.388 1.714.969c.21-.168.456-.296.732-.37l1.932-.517a2 2 0 0 1 2.45 1.414l4.14 15.455a2 2 0 0 1-1.414 2.45l-1.932.517a2 2 0 0 1-2.45-1.414L13 8.663V20a2 2 0 0 1-2 2H9a1.99 1.99 0 0 1-1-.268A1.99 1.99 0 0 1 7 22H5a2 2 0 0 1-2-2V4Zm2 0h2v16H5V4Zm6 16H9V4h2v16Zm2.963-15.469l1.932-.517l4.142 15.455l-1.932.517l-4.142-15.455Z"
        />
      </g>
    </Icon>
  );
}

export default MingcuteBook5Line;
