import React, { ComponentProps } from "react";
import Icon from "./Icon/Icon";

type IconProps = ComponentProps<typeof Icon>;

export function IcOutlineComment(props: IconProps) {
  return (
    <svg width="24px" height="24px" viewBox="0 0 24 24" {...props}>
      <path fill={props.color || "currentColor"} d="M21.99 4c0-1.1-.89-2-1.99-2H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h14l4 4l-.01-18zM20 4v13.17L18.83 16H4V4h16zM6 12h12v2H6zm0-3h12v2H6zm0-3h12v2H6z"></path>
    </svg>
  );
}

export default IcOutlineComment;
